import React, { Fragment, useEffect, useState } from "react";
import windows from "../assets/images/windows.png";
import Linux from "../assets/images/Linux.png";
import "../style/LogMonitor.css"
import { getAllAgentInfo, downloadWindowAgent, getLogMonitorUserInfo } from "../apis/LogMonitor"
import linuxAgent from '../assets/log_monitor/logmonitoragent.zip'
import Popup from './Popup'
import { Link } from 'react-router-dom';
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";

export const AgentDownload = () => {
    const [agentTable, setAgentTable] = useState(false);
    const [allAgentInfo, setAllAgentInfo] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [dowloadLoader, setDowloadLoader] = useState(false);
    let userInfoData = {
        userId: "",
        userName: "",
        accountId: ""
    }
    const [userInfo, setUserInfo] = useState(userInfoData);

    const togglePopup = () => {
        setShowLoader(true);
        let emailId = localStorage.getItem("emailId");
        getLogMonitorUserInfo(emailId).then((response) => {
            setUserInfo(response.data);
            setShowLoader(false);
        })
        setIsOpen(!isOpen);
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!agentTable) {
            setShowLoader(true);
            setAgentTable(true);
        }

        let emailId = localStorage.getItem("emailId");
        getAllAgentInfo(emailId).then((response) => {
            if (agentTable) {
                setAgentTable(false);
            }
            else {
                setAllAgentInfo(response.data);
                setAgentTable(true);
            }

            setShowLoader(false);
        })
    }
    const downLoadAgent = () => {
        setDowloadLoader(true);
        let emailId = localStorage.getItem("emailId");
        downloadWindowAgent(emailId).then((response) => {
            const Link = document.createElement('a');
            Link.href = response.data;
            Link.setAttribute(
                'download',
                'windowsAgent.msi'
            );
            document.body.appendChild(Link);
            Link.click();
            Link.parentNode.removeChild(Link);
            setDowloadLoader(false);
        })

    }
    return (
        <>
            <Fragment>
                <div class="container" style={{ textAlign: 'right' }} >
                    <button type="button" class="btn btn-secondary" onClick={(e) => handleSubmit(e)}>{agentTable ? 'Download Agent' : 'Your Agent List'}</button>
                </div>
                {!agentTable ?
                    <div class="container">
                        <div className="row">
                            <div className="col-6">
                                <div style={{ display: 'inline-block' }}>
                                    <img height="50px" class="m-2" src={windows} alt="" style={{ position: 'relative', top: '-50px', left: '80px' }} />
                                </div>
                                <div style={{ marginLeft: '100px', marginTop: '100px', display: 'inline-block' }} >
                                    <h6>Windows Agent   1.0.0.0</h6>
                                    <p>Log Monitoring Agent for Windows allows<br/>you to monitor application log files based<br/>on select keywords that are configurable</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div style={{ display: 'inline-block' }}>
                                    <img height="50px" class="m-2 imageTag" src={Linux} alt="" style={{ position: 'relative', top: '-70px', left: '80px' }} />
                                </div>
                                <div style={{ marginLeft: '100px', marginTop: '100px', display: 'inline-block' }} >
                                    <h6>Linux Agent   1.0.0.0</h6>
                                    <p>Our Linux Agent supports the following
                                        <br />flavors of Linux OS: Ubuntu, Fedora,<br />Suse and Red Hat

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col-6">
                                <button type="button" class="btn btn-secondary btn-sm buttonColor" style={{ marginLeft: '164px' }} onClick={downLoadAgent}>
                                    {
                                        dowloadLoader ?
                                            <div>
                                                <Loader
                                                    themeColor={"white"}
                                                    size="small"
                                                    type="converging-spinner"
                                                />
                                            </div>
                                            :
                                            <div>Download</div>
                                    }
                                </button>
                            </div>
                            <div className="col-6">
                                <a href={linuxAgent} download style={{ marginLeft: '164px' }}>
                                    <button type="button" class="btn btn-secondary btn-sm buttonColor">Download</button>
                                </a>
                                <button type="button" class="btn btn-secondary btn-sm buttonColor m-3" onClick={togglePopup}>Get Config</button>
                            </div>
                        </div>
                        <div>
                            <div class="container">

                                {isOpen && <Popup
                                    content={<>
                                        <div>
                                            <div class="masterTableTitle text-dark fw-bolder fs-5">
                                                Linux Agent Credential
                                            </div>
                                            <Fragment>
                                                {showLoader ?
                                                    <div className="w-40  d-flex justify-content-center align-items-center"
                                                        style={{ marginTop: "50px" }} > <Loader
                                                            themeColor={"dark"}
                                                            size="medium"
                                                            type="converging-spinner"
                                                        /></div>
                                                    :
                                                    <Fragment>

                                                        <ul class="linuxAgentCreads">
                                                            <li>
                                                                User ID : {userInfo.userId}
                                                            </li>
                                                            <li>
                                                                User Name : {userInfo.userName}
                                                            </li>
                                                            <li>
                                                                Organization : {userInfo.accountId}
                                                            </li>
                                                        </ul>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        </div>
                                    </>}
                                    handleClose={togglePopup}
                                />}

                            </div>
                        </div>
                    </div>
                    :

                    <div class="container mt-1">
                        <Fragment>
                            {showLoader ?
                                <div className="w-40  d-flex justify-content-center align-items-center"
                                    style={{ marginTop: "100px" }} > <Loader
                                        themeColor={"dark"}
                                        size="medium"
                                        type="converging-spinner"
                                    /></div>
                                :
                                <Fragment>
                                    {allAgentInfo.length != 0 ?
                                        <div>
                                            <div>
                                                <div class="masterTableTitle text-dark fw-bolder fs-5">
                                                    Your Agent List
                                                </div>
                                                <ul class="masterTableHeader">
                                                    <li class="w-20">
                                                        Agent ID
                                                    </li>
                                                    <li class="w-20">
                                                        Server Name
                                                    </li>
                                                    <li class="w-20">
                                                        Most Recent Log Entry
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="masterTableBodyDivision">
                                                {
                                                    allAgentInfo.map((agent, index) => (
                                                        <ul
                                                            key={"body" + index}
                                                            className="masterTableBody border-bottom mb-0 py-3"
                                                        >

                                                            <li
                                                                className="w-20 d-flex justify-content-center align-items-center"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <div style={{ wordBreak: "break-word" }}>{agent.AgentId}</div>
                                                            </li>
                                                            <li className="w-20 d-flex justify-content-center align-items-center">
                                                                <div>
                                                                    <Link to={`/log_monitor/log/${agent.AgentId}`} state={agent.ServerName} style={{ textDecoration: 'none' }} >
                                                                        {agent.ServerName}
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                            <li className="w-20 d-flex justify-content-center align-items-center">
                                                                <div>
                                                                    {
                                                                        agent.LastTimeServerResponsed == null ? "Never" :
                                                                            <div>
                                                                                <p>
                                                                                    {
                                                                                        moment(agent.LastTimeServerResponsed).format('llll')
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div class="text-danger">
                                                Please download and install log monitor agent on your server
                                            </div>
                                        </div>
                                    }

                                </Fragment>
                            }
                        </Fragment>
                    </div>
                }
            </Fragment>
        </>
    )
}