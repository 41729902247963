import axios from "axios";
import { toast } from "react-toastify";

export const submitApmMatrixFiles = async (formData) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_PORT}/apm_metrics`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    toast.error("Something Went Wrong !");
  }
};

export const submitApmSelectedMatrixData = async (data) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_PORT}/import_metrics`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
