import { Button, Col, Form } from "react-bootstrap";

export const MatrixTableComponent = (props) => {
    let {apmMatrixData, selectAll, selectCheckboxData, handleApmMatrixData} = props
  return (
    <>
      {Object.keys(apmMatrixData.data[apmMatrixData.index]).map((parentKey,parentKeyIndex) => {
        return (
          <div className="apmMatrixTableDivision mt-3 border p-0" key={`parentKey${parentKeyIndex}`}>
            <div className="title fontStyle fs-6 px-3 py-2 border-bottom d-flex align-items-center">
              {`${parentKey}`}
              <span
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  fontWeight: 400,
                }}
              >{`(${apmMatrixData.index + 1} / ${
                apmMatrixData.data.length
              })`}</span>
            </div>
            <div className="apmMatrixDivision px-5">
              <div className="d-flex py-2">
                <Col className="col-1 justify-content-center d-flex">
                  <span className="fontStyle fs-6 d-flex">
                    <Form.Check
                      key={`${parentKey}-${apmMatrixData.index + 1}`}
                      type={"checkbox"}
                      onChange={() => selectAll(parentKey)}
                      checked={
                        apmMatrixData.selectAllCheck[parentKey] ? true : false
                      }
                      className="me-2"
                    />{" "}
                    Select
                  </span>
                </Col>
                <Col className="col-2 text-center">
                  <span className="fontStyle fs-6">Index</span>
                </Col>
                <Col className="col-9 text-center">
                  <span className="fontStyle fs-6">Label</span>
                </Col>
              </div>
            </div>
            <div className="apmMatrixTable px-5 border-top border-bottom">
              {Object.keys(
                apmMatrixData.data[apmMatrixData.index][parentKey]
              ).map((childKey, index) => {
                return (
                  <>
                    <div className="d-flex py-3" key={"apiMatrix" + index}>
                      <Col className="text-center col-1">
                        <Form.Check
                          type={"checkbox"}
                          onChange={() =>
                            selectCheckboxData(parentKey, childKey)
                          }
                          checked={
                            apmMatrixData.objectOfId[parentKey]?.includes(
                              childKey
                            )
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col className="text-center col-2">{childKey}</Col>
                      <Col className="text-center col-9">
                        {
                          apmMatrixData.data[apmMatrixData.index][parentKey][
                            childKey
                          ]
                        }
                      </Col>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="d-flex justify-content-between py-3">
              {apmMatrixData.index === null ||
              apmMatrixData.index === 0 ? null : (
                <Button
                  type="button"
                  onClick={() => handleApmMatrixData("prev")}
                  className="mx-2 col-1 me-auto"
                >
                  <span className="fontStyle">Prev</span>
                </Button>
              )}
              {apmMatrixData.index === apmMatrixData.data.length - 1 ? null : (
                <Button
                  type="button"
                  onClick={() => handleApmMatrixData("next")}
                  className="mx-2 col-1 ms-auto"
                >
                  <span className="fontStyle">Next</span>
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
