import React from "react";
import "../style/LogMonitor.css"

const Popup = props => {
    return (
        <div class="container">
            <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={props.handleClose}>x</span>
                    {props.content}
                </div>
            </div>
        </div>
    );
};

export default Popup;