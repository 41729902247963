import * as React from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { groupBy, orderBy, filterBy } from "@progress/kendo-data-query";
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Loader } from "@progress/kendo-react-indicators";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";

const CustomHeader = (props) => {
    return (
        <div className="d-flex justify-content-center fontStyle">{props.title}</div>
    );
};

const style = {
    verticalAlign: "top",
};
const CustomCell = (props) => {
    const field = props.field || "";
    return <td style={style}>{props.dataItem[field]}</td>;
};

const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};

export const Datatable = ({
    initialData,
    headerData,
    style,
    initialGrouping,
    initialSort,
    initialFilter,
    initialDataState,
    loader,
    restDatatableData,
    onPageChange,
    dataCount,
    filterChange,
    exportCSVFunction,
    csvButtonStatus,
}) => {
    const _export = React.useRef(null);

    const excelExport = () => {
        exportCSVFunction();
    };
    const [page, setPage] = React.useState(initialDataState);
    const pageChange = (event) => {
        onPageChange(event.page);
        setPage(event.page);
    };
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState(initialSort);
    const [initialGroupingData, setInitialGroupingData] =
        React.useState(initialGrouping);
    const [group, setGroup] = React.useState(initialGroupingData);
    const [resultState, setResultState] = React.useState(
        processWithGroups(initialData, initialGroupingData)
    );

    const [collapsedState, setCollapsedState] = React.useState([]);
    const onGroupChange = React.useCallback(
        (event) => {
            const newDataState = processWithGroups(initialData, event.group);
            setInitialGroupingData(event.group);
            setGroup(event.group);
            setResultState(newDataState);
        },
        [initialData]
    );
    const onExpandChange = React.useCallback(
        (event) => {
            const item = event.dataItem;

            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );
    const newData = setExpandedState({
        data: resultState,
        collapsedIds: collapsedState,
    });
    const onSortChange = React.useCallback(
        (event) => {
            if (event.sort.length) {
                let data = orderBy(newData, event.sort);
                setResultState(data);
            } else {
                setResultState(initialData);
            }
            setSort(event.sort);
        },
        [newData, initialData]
    );
    const onFilterChange = React.useCallback(
        (event) => {
            if (event) {
                filterChange(event);
                setFilter(event.filter);
            } else {
                setFilter(event.filter);
            }
        },
        [initialData]
    );

    React.useEffect(() => {
        setResultState(processWithGroups(initialData, initialGroupingData));
        setPage({ skip: initialDataState.skip, take: initialDataState.take });
    }, [initialData, initialGroupingData, initialDataState]);
    return (
        <>
            {loader ? (
                <>
                    <div
                        style={style}
                        className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <Loader
                            themeColor={"dark"}
                            size="medium"
                            type="converging-spinner"
                        />
                        &nbsp;&nbsp;
                        <div>Please Wait...</div>
                    </div>
                </>
            ) : (
                <ExcelExport ref={_export}>
                    <Grid
                        style={style}
                        data={newData}
                        groupable={restDatatableData.groupable}
                        onGroupChange={onGroupChange}
                        group={group}
                        onExpandChange={onExpandChange}
                        expandField="expanded"
                        sort={sort}
                        sortable={true}
                        onSortChange={onSortChange}
                        filterable={restDatatableData.filterable}
                        filter={filter}
                        onFilterChange={onFilterChange}
                        resizable={true}
                        skip={page.skip}
                        take={page.take}
                        total={dataCount}
                        pageable={restDatatableData.pageable}
                        onPageChange={pageChange}
                    >
                        <GridToolbar>
                            <div className="d-flex justify-content-end w-100">
                                <button
                                    title="Export Excel"
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btn btn-primary"
                                    onClick={excelExport}
                                    disabled={csvButtonStatus}
                                >
                                    Export to Excel
                                </button>
                            </div>
                        </GridToolbar>
                        {headerData.map((value, index) => {
                            return (
                                <GridColumn
                                    key={index}
                                    field={value.field}
                                    title={value.title}
                                    minResizableWidth={value.minResizableWidth}
                                    width={value.width}
                                    headerCell={CustomHeader}
                                    cell={CustomCell}
                                />
                            );
                        })}
                    </Grid>
                </ExcelExport>
            )}
        </>
    );
};