import { Header } from "../components/Header";
import { Breadcrumbs } from "../components/BreadCrumbs";
import { Footer } from "../components/Footer";
import { createContext, useContext, useState } from "react";
import { Header1 } from "../pages/Header1";

export const WorkSpace = createContext();

export const UpdateWorkSpace = createContext();

export function useWorkSpaceUpdate() {
  return useContext(UpdateWorkSpace);
}
export function useWorkSpace() {
  return useContext(WorkSpace);
}

export const WrapperComponent = ({ breadCrumbData, children, path }) => {
  let [workSpaceValue, setWorkSpaceValue] = useState({
    workSpaceIdArray: [],
    workSpaceId: "",
    masterIdList: [],
    currentMasterId: null,
    showWorkSpace: false
  });

  let toggle = (props) => {
    let { workSpaceIdArray, workSpaceId, masterIdList, currentMasterId, showWorkSpace } =
      props;
    setWorkSpaceValue({
      workSpaceIdArray: workSpaceIdArray,
      workSpaceId: workSpaceId,
      masterIdList: masterIdList,
      currentMasterId: currentMasterId,
      showWorkSpace: showWorkSpace
    });
  };
  return (
    <>
      <WorkSpace.Provider value={workSpaceValue}>
        <UpdateWorkSpace.Provider value={toggle}>
          <Header1 />
          {/* <Breadcrumbs title={breadCrumbData} path={path}></Breadcrumbs> */}
          {children}
          {/* <Footer /> */}
        </UpdateWorkSpace.Provider>
      </WorkSpace.Provider>
    </>
  );
};