import { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col, Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Datatable } from "../components/datatable/Datatable";
import {
  getErrorDatatableAllData,
  getSessionId,
  getSessionIdData,
  getTimeStampDetails,
} from "../apis/ErrorAnalysis";
import "react-toastify/dist/ReactToastify.css";
import "../style/ErrorAnalysis.css";
import { ApiAndSecretForm } from "../components/creds_info/ApiAndSecretForm";
import {
  submitApmMatrixFiles,
  submitApmSelectedMatrixData,
} from "../apis/APMMatrix";
import { MatrixTableComponent } from "../components/apm_matrix/TableComponent";
import { AlertBootstrap } from "../components/error_analysis/Alert";
import moment from "moment";
import { CustomTable } from "../components/CustomTable";
import { Loader } from "@progress/kendo-react-indicators";
import { useParams } from "react-router-dom";
import { useWorkSpace } from "../routes/WrapperComponent";
import Select from "react-select";
import { getAllAgentInfo, getLogSettings, getLogEntries } from "../apis/LogMonitor";

export const ErrorAnalysis = () => {
  const workSpaceData = useWorkSpace();
  const { id } = useParams();
  const [apiSecretData, setApiSecretData] = useState(null);
  const [errorDataTableData, setErrorDataTableData] = useState({
    totalErrorCount: null,
    errorData: [],
  });
  const [csvButtonStatus, setCsvButtonStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sessionId, setSessionId] = useState([]);
  const [sessionIdData, setSessionIdData] = useState([]);
  const [showApiSecretForm, setShowApiSecretForm] = useState(true);
  const [masterFormData, setMasterFormData] = useState("");
  const [masterFormSubmitButtonStatus, setMasterFormSubmitButtonStatus] =
    useState(false);
  const [masterFormValidated, setMasterFormValidated] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [showId, setShowId] = useState({
    masterId: null,
    selectedSessionId: [],
  });
  const [dataCount, setDataCount] = useState(0);
  const [importPerfmonMetricsModal, setImportPerfmonMetricsModal] =
    useState(false);
  let [apmMatrixFormData, setApmMatrixFormData] = useState({
    masterId: "",
    labelName: "PerfMon Metrics",
    filesArray: [],
    submitButtonStatus: true,
    startTime: "",
    endTime: "",
    submitButtonLoaderStatus: false,
  });
  let [apmMatrixData, setAPMMatrixData] = useState({
    index: null,
    data: [],
    objectOfId: {},
    submitButtonStatus: true,
    selectedFile: [],
    selectAllCheck: {},
    submitButtonLoaderStatus: false,
  });
  let [link, setLink] = useState(null);
  let [dataLimitState, setDataLimitState] = useState({
    skip: 0,
    take: 10,
  });
  let [pageSize, setPageSize] = useState(dataLimitState.take);
  let [queryParamsState, setQueryParamsState] = useState(null);
  useEffect(() => {
    let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
    setApiSecretData(apiSecretData);
    if (apiSecretData) {
      setShowApiSecretForm(false);
    }
    if (id) {
      getSessionIdList(id);
    } else {
      setShowId({
        masterId: null,
        selectedSessionId: null,
      });

      setErrorDataTableData({
        totalErrorCount: null,
        errorData: [],
      });
      setSessionId([]);
      setSessionIdData([]);
      setMasterFormData("");
    }
  }, [id]);

  let error_analysis_input = document.getElementById("error_analysis_input");
  useEffect(() => {
    if (
      error_analysis_input &&
      !error_analysis_input.classList.contains("col-12")
    ) {
      error_analysis_input.classList.add("col-12");
    }
  }, [error_analysis_input]);

  const getErrorData = (data, selectedSessionId) => {
    let finalErrorCountArray = selectedSessionId.reduce((cur, next) => {
      if (data && data[next] && data[next].countByErrorCode) {
        Object.keys(data[next].countByErrorCode).map((item) => {
          let index = cur.findIndex(
            (obj) => String(obj.errorCode) === String(item)
          );
          if (index !== -1)
            cur[index]["errorCount"] += data[next].countByErrorCode[item];
          else
            cur.push({
              errorCode: item,
              errorCount: data[next].countByErrorCode[item],
            });
        });
        return cur;
      } else {
        return cur;
      }
    }, []);
    return finalErrorCountArray;
  };
  const handleChange = (e, formValue) => {
    let value = e.target.value;
    setMasterFormData(value);
  };
  const handleSubmit = (event, submitValue) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      getSessionIdList(+masterFormData);
      // getAllAgentData()
    } else {
      setMasterFormValidated(true);
    }
  };

  // MODAL SECTION STARTS HERE
  const handleImportPerfmonMetrics = () => {
    let timeStampDataObject = {};
    let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
    timeStampDataObject["apiKey"] = apiSecretData.key;
    timeStampDataObject["apiSecret"] = apiSecretData.secret;
    timeStampDataObject["masterId"] = showId.masterId;
    getTimeStampDetails(timeStampDataObject)
      .then((response) => {
        setApmMatrixFormData((preVal) => ({
          ...preVal,
          masterId: showId.masterId,
          startTime: moment(response.data.data.created * 1000).format(
            "MMM DD, YYYY hh:mm:ss A"
          ),
          endTime: moment(response.data.data.ended * 1000).format(
            "MMM DD, YYYY hh:mm:ss A"
          ),
        }));
      })
      .catch((error) => {
        console.log("Timestamp Error", error.message);
      });
    setImportPerfmonMetricsModal(!importPerfmonMetricsModal);
  };
  const handleImportPerfmonMetricsClose = () => {
    resetModal();
    setLink(null);
    setImportPerfmonMetricsModal(!importPerfmonMetricsModal);
  };
  const handleFilesChange = (e) => {
    let filesArray = Array.from(e.target.files);
    let fileListLength = e.target.files.length;
    setApmMatrixFormData((preVal) => ({
      ...preVal,
      submitButtonStatus: fileListLength ? false : true,
      filesArray: filesArray,
    }));
  };
  const handleImportMetricFromSubmit = () => {
    let array = ["Memory", "CPU", "Disk Usage"];
    let formData = new FormData();
    let filesNamesArray = apmMatrixData.selectedFile.map((value) => {
      return value.name;
    });
    let filteredFiles = apmMatrixFormData.filesArray.filter((file) => {
      return !filesNamesArray.includes(file.name);
    });
    filteredFiles.map((file) => {
      return formData.append("perfMonFile", file);
    });
    if (filteredFiles.length) {
      setApmMatrixFormData((preVal) => ({
        ...preVal,
        submitButtonStatus: true,
        submitButtonLoaderStatus: true,
      }));
      submitApmMatrixFiles(formData).then((response) => {
        let preSelectedObject = {};
        response.data.map((files) => {
          let fileName = Object.keys(files)[0];
          let fileValue = Object.values(files)[0];
          let fileValueKeys = Object.keys(fileValue);
          let idArray = [];
          fileValueKeys.map((id) => {
            array.map((value) => {
              if (files[fileName][id].includes(value)) {
                idArray.push(id);
              }
              return null;
            });
            if (idArray.length) {
              preSelectedObject[fileName] = [...idArray];
            }
            return null;
          });
          return null;
        });
        setAPMMatrixData({
          ...apmMatrixData,
          index: 0,
          data: [...apmMatrixData.data, ...response.data],
          submitButtonStatus: true,
          selectedFile: [...apmMatrixData.selectedFile, ...filteredFiles],
          objectOfId: preSelectedObject,
        });
        setApmMatrixFormData((preVal) => ({
          ...preVal,
          filesArray: [],
          submitButtonStatus: true,
          submitButtonLoaderStatus: false,
        }));
      });
    }
    document.getElementById("apiMatrixForm").reset();
  };
  const selectAll = (filename) => {
    if (
      apmMatrixData.objectOfId[filename] &&
      apmMatrixData.objectOfId[filename].length ===
      Object.keys(apmMatrixData.data[apmMatrixData.index][filename]).length
    ) {
      let tempData = { ...apmMatrixData.objectOfId };
      delete tempData[filename];
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...tempData },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [filename]: false,
        },
      });
    } else {
      let tempData = { ...apmMatrixData };
      tempData.objectOfId[filename] = Object.keys(
        Object.values(
          apmMatrixData.data[
          apmMatrixData.data.findIndex(
            (obj) => Object.keys(obj)[0] === filename
          )
          ]
        )[0]
      );
      tempData.selectAllCheck = {
        ...apmMatrixData.selectAllCheck,
        [filename]: true,
      };
      setAPMMatrixData(tempData);
    }
  };
  const selectCheckboxData = (key, itemIndex) => {
    let initiatedkeys = Object.keys(apmMatrixData.objectOfId);
    if (initiatedkeys.includes(key)) {
      let oldObjectOfId = apmMatrixData.objectOfId;
      let targetObject = oldObjectOfId[key];
      if (targetObject.includes(itemIndex)) {
        let indexOfItem = targetObject.indexOf(itemIndex);
        targetObject.splice(indexOfItem, 1);
        if (targetObject.length === 0) {
          delete oldObjectOfId[key];
        }
      } else {
        targetObject.push(itemIndex);
      }
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...oldObjectOfId },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [key]:
            oldObjectOfId[key] &&
              oldObjectOfId[key].length ===
              Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
              ? true
              : false,
        },
      });
    } else {
      let oldObjectOfId = apmMatrixData.objectOfId;
      let object = {
        [key]: [itemIndex],
      };
      oldObjectOfId = { ...oldObjectOfId, ...object };
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...oldObjectOfId },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [key]:
            oldObjectOfId[key] &&
              oldObjectOfId[key].length ===
              Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
              ? true
              : false,
        },
      });
    }
  };
  const handleApmMatrixData = (value) => {
    let index;
    if (value === "prev") {
      index = apmMatrixData.index - 1;
    } else {
      index = apmMatrixData.index + 1;
    }

    setAPMMatrixData({
      ...apmMatrixData,
      index: index,
    });
  };
  const handleModalSubmit = () => {
    setAPMMatrixData((preVal) => ({
      ...preVal,
      submitButtonLoaderStatus: true,
    }));
    let masterId = apmMatrixFormData.masterId;
    let labelName = apmMatrixFormData.labelName;
    let objectOfId = apmMatrixData.objectOfId;
    let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
    let formData = new FormData();
    apmMatrixData.selectedFile.map((file) => {
      return formData.append("perfMonFile", file);
    });
    formData.append("masterId", masterId);
    formData.append("apiKey", apiSecretData.key);
    formData.append("apiSecret", apiSecretData.secret);
    formData.append("labelName", labelName);
    formData.append("selectedData", JSON.stringify(objectOfId));

    submitApmSelectedMatrixData(formData)
      .then((response) => {
        if (response.status) {
          setLink(response.data.link);
          setAPMMatrixData({
            index: null,
            data: [],
            renderData: [],
            title: "",
            objectOfId: {},
            submitButtonStatus: true,
            selectedFile: [],
            selectAllCheck: {},
          });
          setApmMatrixFormData((preVal) => ({
            ...preVal,
            filesArray: [],
            submitButtonStatus: true,
            submitButtonLoaderStatus: false,
          }));
        }
      })
      .catch((error) => {
        setApmMatrixFormData((preVal) => ({
          ...preVal,
          filesArray: [],
          submitButtonStatus: true,
        }));
        setAPMMatrixData({
          index: null,
          data: [],
          renderData: [],
          title: "",
          objectOfId: {},
          submitButtonStatus: true,
          selectedFile: [],
          selectAllCheck: {},
        });
      });
  };
  const resetModal = () => {
    setApmMatrixFormData({
      masterId: "",
      labelName: "PerfMon Metrics",
      filesArray: [],
      submitButtonStatus: true,
      startTime: "",
      endTime: "",
      labelNameButtonStatus: true,
    });
    setAPMMatrixData({
      index: null,
      data: [],
      renderData: [],
      title: "",
      objectOfId: {},
      submitButtonStatus: true,
      selectedFile: [],
      selectAllCheck: {},
    });
  };
  // MODAL SECTION ENDS HERE

  const datatableHeader = [
    {
      field: "srNo",
      title: "Sr No",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "sessionId",
      title: "Engine ID",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "timeStamp",
      title: "Time Stamp",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "label",
      title: "Label",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "method",
      title: "Method",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "url",
      title: "URL",
      minResizableWidth: 150,
      width: 150,
    },
    {
      field: "sampleResult",
      title: "Sample Result",
      minResizableWidth: 250,
      width: 250,
    },
    {
      field: "requestHeader",
      title: "Request Header",
      minResizableWidth: 500,
      width: 500,
    },
    {
      field: "requestBody",
      title: "Request Body",
      minResizableWidth: 250,
      width: 500,
    },
    {
      field: "responseHeader",
      title: "Response Header",
      minResizableWidth: 250,
      width: 500,
    },
    {
      field: "responseBody",
      title: "Response Body",
      minResizableWidth: 250,
      width: 500,
    },
  ];
  const style = {
    height: "680px",
  };
  const initialGrouping = [];
  const initialSort = [];
  const initialFilter = {
    logic: "and",
  };

  const pageChange = (event) => {
    getSessionIdData(
      apiSecretData,
      showId.selectedSessionId.map((value) => value.value),
      masterFormData,
      sessionId.map((value) => value.value),
      event.skip,
      event.take,
      queryParamsState
    )
      .then((response) => {
        if (response?.data?.status) {
          let errorTableData = getErrorData(
            response.data.resultData.masterIdDetails.sessionErrors,
            showId.selectedSessionId.map((value) => value.value)
          );
          setErrorDataTableData((preVal) => ({
            ...preVal,
            errorData: errorTableData,
          }));
          setSessionIdData(response.data.resultData.data);
          setLoader(false);
          setDataCount(response.data.resultData.count);
          setMasterFormSubmitButtonStatus(false);
        } else {
          toast.warn(response?.data?.Message);
          setSessionIdData([]);
          setLoader(false);
          setMasterFormSubmitButtonStatus(false);
        }
      })
      .catch((error) => console.log("ERROR", error));
    setDataLimitState(event);
  };

  function debounce(func, timeout) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function debounceFunction(type, data) {
    let queryParams;
    let selectedSessionId;
    switch (type) {
      case "filter":
        queryParams = data;
        selectedSessionId = showId.selectedSessionId.map(
          (value) => value.value
        );
        break;
      case "multiSelectSessionId":
        queryParams = queryParamsState;
        let newData = data.map((value) => {
          return { value: value, checked: true };
        });
        setShowId((preVal) => ({
          ...preVal,
          selectedSessionId: newData,
        }));
        selectedSessionId = data;
        break;
      default:
        break;
    }
    let sessionIdArray = sessionId.map((value) => value.value);
    setDataLimitState({ skip: 0, take: 10 });
    getSessionIdData(
      apiSecretData,
      selectedSessionId,
      masterFormData,
      sessionIdArray,
      0,
      10,
      queryParams
    )
      .then((response) => {
        if (response?.data?.status) {
          let errorTableData = getErrorData(
            response.data.resultData.masterIdDetails.sessionErrors,
            selectedSessionId
          );
          let flag = true;
          let finalCount = Object.values(
            response?.data?.resultData?.masterIdDetails?.sessionErrors
          ).reduce((cur, next) => {
            if (!next) {
              flag = false;
            }
            return cur + (next && next.totalCount);
          }, 0);
          setErrorDataTableData({
            totalErrorCount: flag ? finalCount : null,
            errorData: errorTableData,
          });
          setSessionIdData(response.data.resultData.data);
          setLoader(false);
          setDataCount(response.data.resultData.count);
          setMasterFormSubmitButtonStatus(false);
          toast.success(response?.data?.Message);
        } else {
          toast.warn(response?.data?.Message);
          setSessionIdData([]);
          setLoader(false);
          setMasterFormSubmitButtonStatus(false);
        }
      })
      .catch((error) => console.log("ERROR", error));
  }

  const processChange = debounce(
    (type, data) => debounceFunction(type, data),
    1500
  );

  const onFilterChange = (event) => {
    let filterQuery = "";
    if (event.filter) {
      event.filter.filters.map((value, index) => {
        filterQuery += `${index === 0 ? "?" : "&"}${value.field}=${value.value
          }`;
        return null;
      });
      setQueryParamsState(filterQuery);
    } else {
      setQueryParamsState(null);
    }
    processChange("filter", filterQuery);
  };
  const restDatatableData = {
    groupable: true,
    filterable: true,
    pageable: true,
  };
  const selectable = false;

  const handleProfileNameChange = (e) => {
    setApmMatrixFormData((preVal) => ({
      ...preVal,
      labelName: e.target.value,
    }));
  };

  const getSessionIdList = (masterId) => {
    getAllAgentData()
    let masterForm = document.getElementById("masterIdForm");
    setDataLimitState({
      skip: 0,
      take: 10,
    });
    setQueryParamsState(null);
    setSessionIdData([]);
    setSessionId([]);
    setLoader(true);
    setShowId({
      masterId: null,
      selectedSessionId: [],
    });
    setMasterFormSubmitButtonStatus(true);
    setMasterFormValidated(false);
    let formData = JSON.parse(localStorage.getItem("apiSecretData"));
    formData.masterId = +masterId;

    getSessionId(formData)
      .then((response) => {
        if (response) {
          setNoDataFound(false);
          setShowId((preVal) => ({
            ...preVal,
            masterId: masterId,
            selectedSessionId: [{ value: response.data[0], checked: true }],
          }));
          if (masterForm) {
            masterForm.reset();
          }
          setMasterFormData(masterId);
          let sessionIdNewList = response.data.map((value, index) => {
            return { value: value, checked: !index ? true : false };
          });
          setSessionId(sessionIdNewList);
          getSessionIdData(
            formData,
            [response.data[0]],
            masterId,
            response.data,
            0,
            10
          )
            .then((sessionIdDataResponse) => {
              if (sessionIdDataResponse?.data?.status) {
                let errorTableData = getErrorData(
                  sessionIdDataResponse.data.resultData.masterIdDetails
                    .sessionErrors,
                  [response.data[0]]
                );
                let flag = true;
                let finalCount = Object.values(
                  sessionIdDataResponse?.data?.resultData?.masterIdDetails
                    ?.sessionErrors
                ).reduce((cur, next) => {
                  if (!next) {
                    flag = false;
                  }
                  return cur + (next && next.totalCount);
                }, 0);
                setErrorDataTableData({
                  totalErrorCount: flag ? finalCount : null,
                  errorData: errorTableData,
                });
                setSessionIdData(sessionIdDataResponse.data.resultData.data);
                setLoader(false);
                setDataCount(sessionIdDataResponse.data.resultData.count);
                setMasterFormSubmitButtonStatus(false);
                if (sessionIdDataResponse.data.resultData.data.length) {
                  toast.success(sessionIdDataResponse.data.Message);
                }
              } else {
                toast.warn(sessionIdDataResponse?.data?.Message);
                setSessionIdData([]);
                setLoader(false);
                setMasterFormSubmitButtonStatus(false);
              }
            })
            .catch((error) => console.log("ERROR 1", error));
        } else {
          setSessionId([]);
          setSessionIdData([]);
          setLoader(false);
          setNoDataFound(true);
          setMasterFormSubmitButtonStatus(false);
        }
      })
      .catch((error) => console.log("Error 2", error));
  };

  let customTableHeader = [
    "Name",
    "Master Id",
    "Executed",
    "Status",
  ];

  const exportCSVFunction = () => {
    setCsvButtonStatus(true);
    let selectedSessionIdArray = showId.selectedSessionId.map((value) => value.value)
    getErrorDatatableAllData(apiSecretData, showId.masterId, selectedSessionIdArray)
      .then((res) => {
        setCsvButtonStatus(false);
      })
      .catch((err) => {
        setCsvButtonStatus(false);
      });
  }

  const formatOptionLabel = ({ value, checked }) => (
    <div style={{ display: "flex" }}>
      <div>
        <input
          type="checkbox"
          checked={checked}
          id="sessionIdCheckbox"
          onChange={() => selectSessionId({ value: value, checked: checked })}
        />
      </div>
      <div className="ms-3">
        <label htmlFor="sessionIdCheckbox">{value}</label>
      </div>
    </div>
  );
  const selectSessionId = (e) => {
    let data = [...showId.selectedSessionId];
    let sessionIds = [...sessionId];
    if (!e.checked) {
      data.push({ value: e.value, checked: true });
      sessionIds[sessionIds.findIndex((obj) => obj.value === e.value)] = {
        ...e,
        checked: true,
      };
    } else {
      data.splice(
        data.findIndex((obj) => obj.value === e.value),
        1
      );
      sessionIds[sessionIds.findIndex((obj) => obj.value === e.value)] = {
        ...e,
        checked: false,
      };
    }
    let finalData = data.map((value) => value.value);
    processChange("multiSelectSessionId", finalData);
    setSessionId([...sessionIds]);
  };
  const selectAllSessionId = () => {
    setLoader(true);
    setShowId({ ...showId, selectedSessionId: [] });
    setSessionId([]);
    let data = sessionId.map((value) => value.value);
    processChange(
      "multiSelectSessionId",
      sessionId.length !== showId.selectedSessionId.length ? data : []
    );
    let sessionIdNew = sessionId.map((value) => {
      return {
        value: value.value,
        checked:
          sessionId.length !== showId.selectedSessionId.length ? true : false,
      };
    });
    setSessionId(sessionIdNew);
  };

  const [getAllAgent, setGetAllAgent] = useState();
  const [agentInfo, setAgentInfo] = useState();
  const [selectedAgentId, setSelectedAgentId] = useState([]);

  const getAllAgentData = () => {
    const getAgent = [];
    let emailId = localStorage.getItem("emailId");
    getAllAgentInfo(emailId).then((response) => {
      console.log("response data agent list : ", response.data);
      setAgentInfo(response.data)
      response.data.forEach((agent) => getAgent.push({ value: agent.ServerName, label: agent.ServerName }))
    })
    setGetAllAgent(getAgent);
  }

  const selectAgentHandler = (e) => {
    const selectAgent = [];
    e.forEach(agentName => {
      selectAgent.push(agentInfo.filter(agent => agent.ServerName === agentName.value))
    })
    const agentIds = [];
    selectAgent.forEach(agent => {
      agentIds.push(agent[0].AgentId)
    })
    setSelectedAgentId(agentIds);
  }
  return (
    <>
      <Row className="mx-5">
        <ToastContainer />
        {showApiSecretForm ? (
          <ApiAndSecretForm
            setShowApiSecretForm={setShowApiSecretForm}
          ></ApiAndSecretForm>
        ) : (
          <>
            {workSpaceData.masterIdList.length ? (
              <div className="mb-3 d-flex px-0">
                <Col className="col-6 d-flex justify-content-center align-items-center ps-0 pe-2">
                  {workSpaceData.masterIdList.length ? (
                    <div className="masterIdCustomTable w-100 h-100">
                      <CustomTable
                        header={customTableHeader}
                        body={workSpaceData.masterIdList}
                        getSessionIdList={(masterId) =>
                          getSessionIdList(masterId)
                        }
                        showWorkSpace={workSpaceData.showWorkSpace}
                      />
                    </div>
                  ) : (
                    <Loader
                      themeColor={"dark"}
                      size="medium"
                      type="converging-spinner"
                    />
                  )}
                </Col>
                <Col className="col-6 col  mb-0  alert alert-primary ps-2">
                  <Form
                    noValidate
                    validated={masterFormValidated}
                    onSubmit={(e) => handleSubmit(e)}
                    id="masterIdForm"
                  >
                    <div className="">
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <div>
                          <div className="text-dark fw-bolder fs-5" style={{ marginTop: "-10px" }}>
                            Performance Insights
                          </div>
                          <div className="py-1 text-dark">
                            Request/Response details of failed requests !
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content">
                        <Form.Group
                          as={Col}
                          controlId="validationCustom01"
                        >
                          <Form.Control
                            name="masterId"
                            required
                            type="text"
                            defaultValue={masterFormData}
                            autoComplete="off"
                            placeholder="Enter Master ID"
                            onChange={(e) => handleChange(e, "masterForm")}
                          />
                        </Form.Group>
                        <Button
                          className=" ms-1"
                          type="submit"
                          disabled={masterFormSubmitButtonStatus}
                        >
                          <span>Submit</span>
                        </Button>
                        <Button
                          className=" ms-1"
                          onClick={handleImportPerfmonMetrics}
                          type="button"
                        >
                          Import Perfmon Metrics
                        </Button>
                      </div>
                      {noDataFound ? null : sessionId.length ? (
                        <div>
                          <div style={{ marginTop: "15px" }}>
                            <div className="mb-2 d-flex justify-content-between align-items-center">
                              <div>
                                <span className="text-dark fw-bold">
                                  Engine Id
                                </span>{" "}
                                <span className="fst-italic text-dark">{` ( ${showId.selectedSessionId.length} / ${sessionId.length} )`}</span>
                              </div>
                              <div>
                                <Form>
                                  <Form.Check
                                    type={"checkbox"}
                                    className="text-dark"
                                    label={
                                      sessionId.length ===
                                        showId.selectedSessionId.length
                                        ? `Remove All`
                                        : `Select All`
                                    }
                                    id={`default-${`type`}`}
                                    checked={
                                      sessionId.length ===
                                        showId.selectedSessionId.length
                                        ? true
                                        : false
                                    }
                                    onChange={() => selectAllSessionId()}
                                  />
                                </Form>
                              </div>
                            </div>

                            <Select
                              formatOptionLabel={formatOptionLabel}
                              options={sessionId}
                            />
                          </div>
                          <div style={{ marginTop: "15px" }}>
                            <div>
                              <span className="text-dark fw-bold">
                                Agent Id
                              </span>
                            </div>
                            <div className="mt-2">
                              <Select
                                options={getAllAgent}
                                onChange={(e) => selectAgentHandler(e)}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}{" "}
                    </div>
                  </Form>
                </Col>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader
                  themeColor={"dark"}
                  size="medium"
                  type="converging-spinner"
                />
              </div>
            )}

            {sessionId.length ? (
              <>
                <div className="p-0 ">
                  {loader ? null : errorDataTableData.errorData.length ? (
                    <>
                      <AlertBootstrap
                        errorDataArray={errorDataTableData}
                        selectedAgentId={selectedAgentId}
                        masterId={showId.masterId}
                        sessionData={sessionIdData}
                      ></AlertBootstrap>
                    </>
                  ) : null}
                </div>
                <div className="p-0 ">
                  <div className="py-0 mb-3 ps-4 text-dark fw-bolder fs-5">
                    {sessionIdData.length ? "Error Data Table" : null}
                  </div>
                  <Datatable
                    initialData={sessionIdData}
                    headerData={
                      sessionIdData.length
                        ? datatableHeader
                        : datatableHeader.map((obj) => ({
                          field: obj.field,
                          title: obj.title,
                          width: 150,
                        }))
                    }
                    style={style}
                    initialGrouping={initialGrouping}
                    initialSort={initialSort}
                    initialFilter={initialFilter}
                    initialDataState={dataLimitState}
                    loader={loader}
                    restDatatableData={restDatatableData}
                    selectable={selectable}
                    onPageChange={(event) => pageChange(event)}
                    pageSize={pageSize}
                    dataCount={dataCount}
                    filterChange={(event) => onFilterChange(event)}
                    exportCSVFunction={(data) => exportCSVFunction(data)}
                    csvButtonStatus={csvButtonStatus}
                  ></Datatable>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Row>
      <Modal
        show={importPerfmonMetricsModal}
        onHide={handleImportPerfmonMetricsClose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perfmon Connector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="apiMatrixForm">
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Master Id</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={apmMatrixFormData.masterId}
                  />
                </Form.Group>
              </Col>
              <Col className="">
                <Form.Group
                  className="mb-3 w-100 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Profile Name</Form.Label>
                  <div className="d-flex align-items-center position-relative p-0">
                    <Form.Control
                      type="text"
                      value={apmMatrixFormData.labelName}
                      onChange={(e) => {
                        handleProfileNameChange(e);
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={apmMatrixFormData.startTime}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={apmMatrixFormData.endTime}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Select Files</Form.Label>
              <Form.Control
                type="file"
                name="filesArray"
                required
                multiple
                accept=".csv"
                onChange={(e) => handleFilesChange(e)}
              />
            </Form.Group>
          </Form>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              className="fontStyle minWidth"
              disabled={apmMatrixFormData.submitButtonStatus}
              onClick={() => handleImportMetricFromSubmit()}
            >
              {/* Submit */}
              {apmMatrixFormData.submitButtonLoaderStatus ? (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
          {apmMatrixData.data.length ? (
            <>
              <MatrixTableComponent
                apmMatrixData={apmMatrixData}
                selectAll={(parentKey) => selectAll(parentKey)}
                selectCheckboxData={(parentKey, childKey) =>
                  selectCheckboxData(parentKey, childKey)
                }
                handleApmMatrixData={(value) => handleApmMatrixData(value)}
              />
              <div className="my-3 w-100 d-flex">
                <Button
                  type="button"
                  onClick={handleModalSubmit}
                  disabled={
                    apmMatrixData.data.length !==
                    Object.keys(apmMatrixData.objectOfId).length ||
                    apmMatrixData.submitButtonLoaderStatus
                  }
                  className="ms-auto col-3"
                >
                  <span className="fontStyle">
                    {apmMatrixData.submitButtonLoaderStatus ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                      ></Spinner>
                    ) : (
                      "Submit"
                    )}
                  </span>
                </Button>
              </div>
            </>
          ) : link ? (
            <div className="my-5 d-flex justify-content-center">
              You can check BlazeMeter Report wih Perfmon data Here :
              &nbsp;&nbsp;{" "}
              <a href={link} target="_blank" style={{ textDecoration: "none" }}>
                {link}
              </a>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="fontStyle"
            onClick={() => handleImportPerfmonMetricsClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};