import React, { useEffect, useState } from "react";
import "../style/Header.css";
import Logo from "../assets/images/logo.png";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { getUserDetails } from "../apis/authDetails";
import { useWorkSpace, WorkSpace } from "../routes/WrapperComponent";
import { useWorkSpaceUpdate } from "../routes/WrapperComponent";
import { getMasterIdList, getWorkSpaceIdList } from "../apis/ErrorAnalysis";
import CryptoJS from "crypto-js";


export const Header1 = () => {
    const [modalStatus, setModalStatus] = useState(false);
  const workSpaceData = useWorkSpace();
  let updateWorkSpace = useWorkSpaceUpdate();
  let navigate = useNavigate();
  let url=window.location.href.split("/")[window.location.href.split("/").length - 1];
  let apiSecretData = localStorage.getItem("apiSecretData");
  const [showLinks, setShowLinks] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const setActiveNav = (className) => {
    let targetedElement = document.getElementsByClassName(className);
    let allElements = document.getElementsByClassName("common-nav-links");
    for (let i = 0; i < allElements.length; i++) {
      if (allElements[i].classList.contains("nav-active")) {
        allElements[i].classList.remove("nav-active");
      }
    }
    for (let i = 0; i < targetedElement.length; i++) {
      targetedElement[i].classList.add("nav-active");
    }
  };
  const handlelinks = () => {
    let allElements = document.getElementsByClassName("common-nav-links");
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].classList.remove("nav-active");
    }
  };
  useEffect(() => {
    if (apiSecretData) {
      let preUsedWorkspaceId = JSON.parse(localStorage.getItem("workspaceId"));

      getUserDetails(JSON.parse(apiSecretData))
        .then((response) => {
          var userEmailId = CryptoJS.AES.encrypt(
            response.data.result.email,
            process.env.REACT_APP_ENCRYPTION_KEY
          ).toString()
          localStorage.setItem("emailId", userEmailId);
          setUserDetails(response.data.result);
          getWorkSpaceIdList(
            JSON.parse(apiSecretData),
            response?.data?.result?.defaultProject?.accountId
          )
            .then((response) => {
              if (!preUsedWorkspaceId) {
                localStorage.setItem(
                  "workspaceId",
                  JSON.stringify(response.data[0])
                );
              }
              getMasterIdList(
                JSON.parse(apiSecretData),
                preUsedWorkspaceId ? preUsedWorkspaceId.id : response.data[0].id
              )
                .then((masterIdListResponse) => {
                  let workSpaceData = {
                    workSpaceIdArray: response?.data,
                    workSpaceId: preUsedWorkspaceId
                      ? preUsedWorkspaceId
                      : response?.data[0],
                    masterIdList: masterIdListResponse?.data,
                    currentMasterId: masterIdListResponse?.data[0]?.id,
                  };
                  updateWorkSpace(workSpaceData);
                })
                .catch((error) =>
                  console.log("Master id list response error", error)
                );
            })
        })
        .catch((error) => {
          setModalStatus(true);
        });
      setShowLinks(true);
    } else {
      setShowLinks(false);
    }
    //eslint-disable-next-line
  }, []);
  const handleLogout = () => {
    localStorage.clear()
    let clearWorkspaceContext = {
      workSpaceIdArray: [],
      workSpaceId: "",
      masterIdList: [],
      currentMasterId: null,
    }
    updateWorkSpace(clearWorkspaceContext);
    navigate("/");
  };
  const changeWorkSpaceData = (workspaceObject) => {
    localStorage.setItem("workspaceId", JSON.stringify(workspaceObject));
    updateWorkSpace({
      ...workSpaceData,
      showWorkSpace: true
    });

    getMasterIdList(JSON.parse(apiSecretData), workspaceObject.id)
      .then((masterIdListResponse) => {
        console.log("masterIDResponse====>",masterIdListResponse.data)
        let workSpaceDataList = {
          workSpaceIdArray: workSpaceData.workSpaceIdArray,
          workSpaceId: workspaceObject,
          masterIdList: masterIdListResponse.data,
          currentMasterId: masterIdListResponse?.data[0]?.id,
          showWorkSpace: false
        };
        updateWorkSpace(workSpaceDataList);
      })
      .catch((error) =>
        console.log("Master id list response error", error.message)
      );
  };
  const handleClose = () => {
    setModalStatus(false);
    handleLogout();
  };

  return (
    <>
    <Modal
    show={modalStatus}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header>
      <Modal.Title>Message</Modal.Title>
    </Modal.Header>
    <Modal.Body>Session Expired ! Please check your credentials</Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleClose}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>
   <nav className="navbar navbar-expand-lg bg-light" style={{marginBottom: window.location.href.endsWith("performance_insights") ? '0px' : '100px'}}>
    <div className="container-fluid">
        <img height="40px" src={Logo} alt="" />
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        {showLinks ? (
            <div className="d-flex align-items-center">
                <Link
                    to="/performance_insights"
                    className="nav-link text-dark p-0 d-flex flex-column justify-content-center align-items-center px-4"
                    style={{ fontWeight: "500", fontSize: "13px" }}
                    onClick={() => setActiveNav("errorAnalysis")}
                >
                    <DescriptionOutlinedIcon
                        fontSize="medium"
                        className={`${url.includes("performance_insights") ? "nav-active" : null
                            } errorAnalysis common-nav-links`}
                    />
                    <span
                        className={`${url === "performance_insights" ? "nav-active" : null
                            } errorAnalysis common-nav-links`}
                        style={{fontSize :"13px"}}
                    >
                        Performance Insights
                    </span>
                </Link>
            </div>
        ) : null}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        {showLinks ? (
            <div className="d-flex align-items-center">
                <Link
                    to="/log_monitor"
                    className="nav-link text-dark p-0 d-flex flex-column justify-content-center align-items-center px-4"
                    style={{ fontWeight: "500", fontSize: "13px" }}
                    // onClick={() => setActiveNav("errorAnalysis")}
                >
                    <DescriptionOutlinedIcon
                        fontSize="medium"
                        className={`${url.includes("log_monitor") ? "nav-active" : null
                            } errorAnalysis common-nav-links`}
                    />
                    <span
                        className={`${url === "log_monitor" ? "nav-active" : null
                            } errorAnalysis common-nav-links`}
                        style={{fontSize :"13px"}}
                    >
                        Log Monitor
                    </span>
                </Link>
            </div>
        ) : null}

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ marginLeft: "72%" }}>
                <Dropdown className="d-flex justify-content-end align-items-end me-2 ">
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {" "}
                    <span className="fontStyle">
                      {workSpaceData?.workSpaceId && workSpaceData?.workSpaceIdArray.length
                        ? workSpaceData?.workSpaceIdArray.find(
                          (value) => value.id === workSpaceData?.workSpaceId.id
                        ).name
                        : "Workspace Name"}{" "}
                    </span>

                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="p-0"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                        {
                      <WorkSpace.Consumer>
                        {(workSpaceValue) => {
                          return workSpaceValue.workSpaceIdArray.map(
                            (value, index) => {
                              return (
                                <Dropdown.Item
                                  className="py-2 fontStyle"
                                  key={`workSpaceId${index}`}
                                  onClick={() => {
                                    changeWorkSpaceData(value);
                                  }}
                                >
                                  {value.name}
                                </Dropdown.Item>
                              );
                            }
                          );
                        }}
                      </WorkSpace.Consumer>
                    }
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
            <div>
                <Dropdown>
                    <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        variant="light"
                        className="d-flex align-items-center"
                    >
                        <label
                            className="d-flex justify-content-center fontStyle text-dark fw-bold"
                            style={{ fontSize: "15px" }}
                        >
                            Hi {userDetails?.firstName}
                        </label>
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="light">
                        <Dropdown.Item
                            href="#/action-1"
                            className="p-0 removeHover py-1"
                        >
                            <Link
                                to="/apm_connector"
                                className="nav-link nav-link-color fw-800 p-0 fontStyle text-center "
                                style={{ fontSize: "15px" }}
                                onClick={() => handlelinks()}
                            >
                                Perfmon Connector
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                            href="#/action-4"
                            className="p-0 removeHover py-1"
                        >
                            <Link
                                to="/log_monitor"
                                className="nav-link nav-link-color fw-800 p-0 fontStyle text-center "
                                style={{ fontSize: "15px" }}
                                onClick={() => handlelinks()}
                            >
                                Log Monitor
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                            href="#/action-2"
                            className="p-0 removeHover py-1"
                        >
                            {" "}
                            <Link
                                to="/setting"
                                className="nav-link nav-link-color fw-800 p-0 fontStyle text-center "
                                style={{ fontSize: "15px" }}
                                onClick={() => handlelinks()}
                            >
                                Settings
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="p-0 removeHover py-1"
                        >
                            <Button
                                className="nav-link nav-link-color fw-800 p-0 fontStyle text-center "
                                style={{
                                    fontSize: "15px",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    border: "none",
                                }}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </div>
</nav></>
  )
}
