import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../apis/authDetails";

export const ApiAndSecretForm = ({ setShowApiSecretForm, ...props }) => {
  const navigate = useNavigate();
  const [invalidCreds, setInvalidCreds] = useState("");
  const [apiFormValidated, setApiFormValidated] = useState(false);
  const [apiAndSecretFormData, setApiAndSecretFormData] = useState({
    key: "",
    secret: "",
    formDataButtonStatus: true,
  });
  const handleSubmit = (event) => {
    console.log("before user login ")
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      var cipherKey = CryptoJS.AES.encrypt(
        apiAndSecretFormData.key,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      var cipherSecret = CryptoJS.AES.encrypt(
        apiAndSecretFormData.secret,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      let data = {
        key: cipherKey,
        secret: cipherSecret,
      };
      getUserDetails(data)
        .then((response) => {
          console.log("after user login response",response.data);
          localStorage.setItem("apiSecretData", JSON.stringify(data));
          console.log("after user login data ",data);
          setApiAndSecretFormData({
            key: "",
            secret: "",
            formDataButtonStatus: false,
          });
         console.log("api and secrert Data====>",apiAndSecretFormData); 
          setApiFormValidated(false);
          setShowApiSecretForm(false);
          form.reset();
          toast.success("API Keys saved Successfully");
          navigate("performance_insights");
        })
        .catch((error) => {
          console.log("Invalid Credentials Error :- ", error);
          setInvalidCreds("Invalid Credentials");
        });
    } else {
      setApiFormValidated(true);
    }
  };
  useEffect(() => {
    if (apiAndSecretFormData.key !== "" && apiAndSecretFormData.secret !== "") {
      setApiAndSecretFormData({
        ...apiAndSecretFormData,
        formDataButtonStatus: false,
      });
      console.log("...apiAndSecretFormData",apiAndSecretFormData);
    } else {
      setApiAndSecretFormData({
        ...apiAndSecretFormData,
        formDataButtonStatus: true,
      });
      console.log("...apiAndSecretFormData in else",apiAndSecretFormData);
    }
    //eslint-disable-next-line
  }, [apiAndSecretFormData.key, apiAndSecretFormData.secret]);
  const handleChange = (e) => {
    if (invalidCreds) {
      setInvalidCreds("");
    }
    let name = e.target.name;
    let value = e.target.value;
    setApiAndSecretFormData({
      ...apiAndSecretFormData,
      [name]: value,
    });
  };
  return (
    <>
      <ToastContainer />
      <Form
        noValidate
        validated={apiFormValidated}
        onSubmit={(e) => handleSubmit(e)}
        className="d-flex flex-column"
      >
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="12"
            className="mb-3"
            controlId="validationCustom01"
          >
            <Form.Control
              name="key"
              required
              type="text"
              placeholder="Enter BlazeMeter API Key"
              defaultValue={apiAndSecretFormData.key}
              onChange={(e) => handleChange(e)}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            className="mb-3"
            controlId="validationCustom02"
          >
            <Form.Control
              name="secret"
              required
              type="text"
              placeholder="Enter BlazeMeter API Secret"
              defaultValue={apiAndSecretFormData.secret}
              onChange={(e) => handleChange(e)}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Label className="text-danger">{invalidCreds}</Form.Label>
        </Row>

        <Button
          type="submit"
          disabled={apiAndSecretFormData.formDataButtonStatus}
          className="btn btn-primary w-25"
          style={{ marginLeft: "75%" }}
        >
          <span className="fontStyle">Save</span>
        </Button>
      </Form>
    </>
  );
};
