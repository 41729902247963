import axios from "axios";

export const getUserDetails = async ({ key, secret }) => {
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_PORT}/get_user_details`, {
      apikey: key,
      apisecret: secret,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw Error(error.response.data.message);
    });
};
