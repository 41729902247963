import React from 'react';
// import { Button, Form, Row, Col, Modal, Spinner } from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import Select, { components, InputProps } from "react-select";
import { ToastContainer } from "react-toastify";
import { Datatable } from "../components/datatable/Datatable1";
import { Button, Form, Col, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { MatrixTableComponent } from "../components/apm_matrix/TableComponent";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import {
    getErrorDatatableAllData,
    getScenarioId,
    getSessionId,
    getSessionIdData,
    getTimeStampDetails,
} from "../apis/ErrorAnalysis";
import "react-toastify/dist/ReactToastify.css";
import "../style/ErrorAnalysis.css";
import {
    submitApmMatrixFiles,
    submitApmSelectedMatrixData,
} from "../apis/APMMatrix";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
import { useParams } from "react-router-dom";
import { getAllAgentInfo, getLogSettings, getLogEntries } from "../apis/LogMonitor";

import "../style/NewUI.css";
import { Link, useNavigate } from "react-router-dom";
import { getUserDetails } from "../apis/authDetails";
import { useWorkSpace } from "../routes/WrapperComponent";
import { getMasterIdList, getWorkSpaceIdList } from "../apis/ErrorAnalysis";
import CryptoJS from "crypto-js";
export const NewUi = () => {
    const workSpaceData = useWorkSpace();
    // console.log("workspace",workSpaceData)

    const { id } = useParams();
    let navigate = useNavigate();
    const [selectGrapToggle, setSelectGrapToggle] = useState("graph");
    const [userDetails, setUserDetails] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [getAllAgent, setGetAllAgent] = useState([]);
    const [agentInfo, setAgentInfo] = useState();
    const [selectedAgentId, setSelectedAgentId] = useState([]);
    const [agentListValue, setAgentListValue] = useState([]);
    let url =
        window.location.href.split("/")[window.location.href.split("/").length - 1];

    const getAllAgentData = () => {
        setGetAllAgent([])
        const getAgent = [];
        let emailId = localStorage.getItem("emailId");
        getAllAgentInfo(emailId).then((response) => {
            setAgentInfo(response.data)
            // response.data.length === 0 ? getAgent.push({ value: "Create New Agent", label: "+ Create New Agent" }) : response.data.forEach((agent) => getAgent.push({ value: agent.ServerName, label: agent.ServerName }))
            response.data.forEach((agent) => getAgent.push({ value: agent.ServerName, label: agent.ServerName }));
            getAgent.push({ value: "Create New Agent", label: "+ Create New Agent" });
            setGetAllAgent(getAgent);
        })
    }
    const selectAgentHandler = (e) => {
        if (e[0]?.value === "Create New Agent") {
            navigate("/log_monitor")
            return;
        }
        else {
            setAgentListValue(e)
            const selectAgent = [];
            e.forEach(agentName => {
                selectAgent.push(agentInfo.filter(agent => agent.ServerName === agentName.value))
            })
            const agentIds = [];
            selectAgent.forEach(agent => {
                agentIds.push(agent[0].AgentId)
            })
            setSelectedAgentId(agentIds);
        }
    }
    const [apiSecretData, setApiSecretData] = useState(null);
    const [errorDataTableData, setErrorDataTableData] = useState({
        totalErrorCount: null,
        errorData: [],
    });
    const [csvButtonStatus, setCsvButtonStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [sessionId, setSessionId] = useState([]);
    const [sessionIdData, setSessionIdData] = useState([]);
    const [scenario, setScenario] = useState({
        scenarioIdList: [],
        selectedScenarioIdList: []
    });
    const [showApiSecretForm, setShowApiSecretForm] = useState(true);
    const [masterFormData, setMasterFormData] = useState("");
    const [masterFormSubmitButtonStatus, setMasterFormSubmitButtonStatus] = useState(false);
    const [masterFormValidated, setMasterFormValidated] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [showId, setShowId] = useState({
        masterId: null,
        selectedSessionId: [],
    });
    const [dataCount, setDataCount] = useState(0);
    const [importPerfmonMetricsModal, setImportPerfmonMetricsModal] =
        useState(false);
    let [apmMatrixFormData, setApmMatrixFormData] = useState({
        masterId: "",
        labelName: "PerfMon Metrics",
        filesArray: [],
        submitButtonStatus: true,
        startTime: "",
        endTime: "",
        submitButtonLoaderStatus: false,
    });
    let [apmMatrixData, setAPMMatrixData] = useState({
        index: null,
        data: [],
        objectOfId: {},
        submitButtonStatus: true,
        selectedFile: [],
        selectAllCheck: {},
        submitButtonLoaderStatus: false,
    });
    let [link, setLink] = useState(null);
    let [dataLimitState, setDataLimitState] = useState({
        skip: 0,
        take: 10,
    });
    let [pageSize, setPageSize] = useState(dataLimitState.take);
    let [queryParamsState, setQueryParamsState] = useState(null);
    useEffect(() => {
        let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
        setApiSecretData(apiSecretData);
        if (apiSecretData) {
            setShowApiSecretForm(false);
        }
        if (id) {
            getSessionIdList(id, scenario.selectedScenarioIdList, scenario.scenarioIdList.length);
        } else {
            setShowId({
                masterId: null,
                selectedSessionId: null,
            });

            setErrorDataTableData({
                totalErrorCount: null,
                errorData: [],
            });
            setSessionId([]);
            setSessionIdData([]);
            setMasterFormData("");
        }
    }, [id]);

    let error_analysis_input = document.getElementById("error_analysis_input");
    useEffect(() => {
        if (
            error_analysis_input &&
            !error_analysis_input.classList.contains("col-12")
        ) {
            error_analysis_input.classList.add("col-12");
        }
    }, [error_analysis_input]);

    const getErrorData = (data, selectedSessionId) => {
        let finalErrorCountArray = selectedSessionId.reduce((cur, next) => {
            if (data && data[next] && data[next].countByErrorCode) {
                Object.keys(data[next].countByErrorCode).map((item) => {
                    let index = cur.findIndex(
                        (obj) => String(obj.errorCode) === String(item)
                    );
                    if (index !== -1)
                        cur[index]["errorCount"] += data[next].countByErrorCode[item];
                    else
                        cur.push({
                            errorCode: item,
                            errorCount: data[next].countByErrorCode[item],
                        });
                });
                return cur;
            } else {
                return cur;
            }
        }, []);
        return finalErrorCountArray;
    };
    const handleChange = (e, formValue) => {
        let value = e.target.value;
        setMasterFormData(value);
    };
    const handleSubmit = (event, submitValue) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            let creads = JSON.parse(localStorage.getItem("apiSecretData"));
            creads.masterId = +masterFormData;
            getScenarioId(creads).then((response) => {
                let scenarioIdListArray = [];
                if (response.data.scenariosMapping != undefined) {
                    scenarioIdListArray = response.data.scenariosMapping.map((value, index) => {
                        return { value: value.name, checked: true, id: value.id };
                    })
                } else {
                    scenarioIdListArray = response.data.labels.map((value, index) => {
                        return { value: value.name, checked: true, id: value.id };
                    })
                }
                // let scenarioIdListArray = response.data.scenariosMapping.map((value, index) => {
                //     return { value: value.name, checked: true, id: value.id };
                // })
                setScenario({
                    scenarioIdList: scenarioIdListArray,
                    selectedScenarioIdList: scenarioIdListArray
                })
                getSessionIdList(+masterFormData, scenarioIdListArray, scenarioIdListArray.length);
            })

            // getAllAgentData()
        } else {
            setMasterFormValidated(true);
        }
    };

    // MODAL SECTION STARTS HERE
    const handleImportPerfmonMetrics = () => {
        let timeStampDataObject = {};
        let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
        timeStampDataObject["apiKey"] = apiSecretData.key;
        timeStampDataObject["apiSecret"] = apiSecretData.secret;
        timeStampDataObject["masterId"] = showId.masterId;
        getTimeStampDetails(timeStampDataObject)
            .then((response) => {
                setApmMatrixFormData((preVal) => ({
                    ...preVal,
                    masterId: showId.masterId,
                    startTime: moment(response.data.data.created * 1000).format(
                        "MMM DD, YYYY hh:mm:ss A"
                    ),
                    endTime: moment(response.data.data.ended * 1000).format(
                        "MMM DD, YYYY hh:mm:ss A"
                    ),
                }));
            })
            .catch((error) => {
                console.log("Timestampconsole.log Error", error.message);
            });
        setImportPerfmonMetricsModal(!importPerfmonMetricsModal);
    };
    const handleImportPerfmonMetricsClose = () => {
        resetModal();
        setLink(null);
        setImportPerfmonMetricsModal(!importPerfmonMetricsModal);
    };
    const handleFilesChange = (e) => {
        let filesArray = Array.from(e.target.files);
        let fileListLength = e.target.files.length;
        setApmMatrixFormData((preVal) => ({
            ...preVal,
            submitButtonStatus: fileListLength ? false : true,
            filesArray: filesArray,
        }));
    };
    const handleImportMetricFromSubmit = () => {

        let array = ["Memory", "CPU", "Disk Usage"];
        let formData = new FormData();
        let filesNamesArray = apmMatrixData.selectedFile.map((value) => {
            return value.name;
        });
        let filteredFiles = apmMatrixFormData.filesArray.filter((file) => {
            return !filesNamesArray.includes(file.name);
        });
        filteredFiles.map((file) => {
            return formData.append("perfMonFile", file);
        });
        if (filteredFiles.length) {
            setApmMatrixFormData((preVal) => ({
                ...preVal,
                submitButtonStatus: true,
                submitButtonLoaderStatus: true,
            }));
            submitApmMatrixFiles(formData).then((response) => {
                let preSelectedObject = {};
                response.data.map((files) => {
                    let fileName = Object.keys(files)[0];
                    let fileValue = Object.values(files)[0];
                    let fileValueKeys = Object.keys(fileValue);
                    let idArray = [];
                    fileValueKeys.map((id) => {
                        array.map((value) => {
                            if (files[fileName][id].includes(value)) {
                                idArray.push(id);
                            }
                            return null;
                        });
                        if (idArray.length) {
                            preSelectedObject[fileName] = [...idArray];
                        }
                        return null;
                    });
                    return null;
                });
                setAPMMatrixData({
                    ...apmMatrixData,
                    index: 0,
                    data: [...apmMatrixData.data, ...response.data],
                    submitButtonStatus: true,
                    selectedFile: [...apmMatrixData.selectedFile, ...filteredFiles],
                    objectOfId: preSelectedObject,
                });
                setApmMatrixFormData((preVal) => ({
                    ...preVal,
                    filesArray: [],
                    submitButtonStatus: true,
                    submitButtonLoaderStatus: false,
                }));
            });
        }
        document.getElementById("apiMatrixForm").reset();
    };
    const selectAll = (filename) => {
        if (
            apmMatrixData.objectOfId[filename] &&
            apmMatrixData.objectOfId[filename].length ===
            Object.keys(apmMatrixData.data[apmMatrixData.index][filename]).length
        ) {
            let tempData = { ...apmMatrixData.objectOfId };
            delete tempData[filename];
            setAPMMatrixData({
                ...apmMatrixData,
                objectOfId: { ...tempData },
                selectAllCheck: {
                    ...apmMatrixData.selectAllCheck,
                    [filename]: false,
                },
            });
        } else {
            let tempData = { ...apmMatrixData };
            tempData.objectOfId[filename] = Object.keys(
                Object.values(
                    apmMatrixData.data[
                    apmMatrixData.data.findIndex(
                        (obj) => Object.keys(obj)[0] === filename
                    )
                    ]
                )[0]
            );
            tempData.selectAllCheck = {
                ...apmMatrixData.selectAllCheck,
                [filename]: true,
            };
            setAPMMatrixData(tempData);
        }
    };
    const selectCheckboxData = (key, itemIndex) => {
        let initiatedkeys = Object.keys(apmMatrixData.objectOfId);
        if (initiatedkeys.includes(key)) {
            let oldObjectOfId = apmMatrixData.objectOfId;
            let targetObject = oldObjectOfId[key];
            if (targetObject.includes(itemIndex)) {
                let indexOfItem = targetObject.indexOf(itemIndex);
                targetObject.splice(indexOfItem, 1);
                if (targetObject.length === 0) {
                    delete oldObjectOfId[key];
                }
            } else {
                targetObject.push(itemIndex);
            }
            setAPMMatrixData({
                ...apmMatrixData,
                objectOfId: { ...oldObjectOfId },
                selectAllCheck: {
                    ...apmMatrixData.selectAllCheck,
                    [key]:
                        oldObjectOfId[key] &&
                            oldObjectOfId[key].length ===
                            Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
                            ? true
                            : false,
                },
            });
        } else {
            let oldObjectOfId = apmMatrixData.objectOfId;
            let object = {
                [key]: [itemIndex],
            };
            oldObjectOfId = { ...oldObjectOfId, ...object };
            setAPMMatrixData({
                ...apmMatrixData,
                objectOfId: { ...oldObjectOfId },
                selectAllCheck: {
                    ...apmMatrixData.selectAllCheck,
                    [key]:
                        oldObjectOfId[key] &&
                            oldObjectOfId[key].length ===
                            Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
                            ? true
                            : false,
                },
            });
        }
    };
    const handleApmMatrixData = (value) => {
        let index;
        if (value === "prev") {
            index = apmMatrixData.index - 1;
        } else {
            index = apmMatrixData.index + 1;
        }

        setAPMMatrixData({
            ...apmMatrixData,
            index: index,
        });
    };
    const handleModalSubmit = () => {
        setAPMMatrixData((preVal) => ({
            ...preVal,
            submitButtonLoaderStatus: true,
        }));
        let masterId = apmMatrixFormData.masterId;
        let labelName = apmMatrixFormData.labelName;
        let objectOfId = apmMatrixData.objectOfId;
        let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
        let formData = new FormData();
        apmMatrixData.selectedFile.map((file) => {
            return formData.append("perfMonFile", file);
        });
        formData.append("masterId", masterId);
        formData.append("apiKey", apiSecretData.key);
        formData.append("apiSecret", apiSecretData.secret);
        formData.append("labelName", labelName);
        formData.append("selectedData", JSON.stringify(objectOfId));

        submitApmSelectedMatrixData(formData)
            .then((response) => {

                if (response.status) {
                    setLink(response.data.link);
                    setAPMMatrixData({
                        index: null,
                        data: [],
                        renderData: [],
                        title: "",
                        objectOfId: {},
                        submitButtonStatus: true,
                        selectedFile: [],
                        selectAllCheck: {},
                    });
                    setApmMatrixFormData((preVal) => ({
                        ...preVal,
                        filesArray: [],
                        submitButtonStatus: true,
                        submitButtonLoaderStatus: false,
                    }));
                }
            })
            .catch((error) => {
                setApmMatrixFormData((preVal) => ({
                    ...preVal,
                    filesArray: [],
                    submitButtonStatus: true,
                }));
                setAPMMatrixData({
                    index: null,
                    data: [],
                    renderData: [],
                    title: "",
                    objectOfId: {},
                    submitButtonStatus: true,
                    selectedFile: [],
                    selectAllCheck: {},
                });
            });
    };
    const resetModal = () => {
        setApmMatrixFormData({
            masterId: "",
            labelName: "PerfMon Metrics",
            filesArray: [],
            submitButtonStatus: true,
            startTime: "",
            endTime: "",
            labelNameButtonStatus: true,
        });
        setAPMMatrixData({
            index: null,
            data: [],
            renderData: [],
            title: "",
            objectOfId: {},
            submitButtonStatus: true,
            selectedFile: [],
            selectAllCheck: {},
        });
    };
    // MODAL SECTION ENDS HERE

    const datatableHeader = [
        {
            field: "srNo",
            title: "Sr No",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "sessionId",
            title: "Engine ID",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "timeStamp",
            title: "Time Stamp",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "label",
            title: "Label",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "method",
            title: "Method",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "url",
            title: "URL",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "sampleResult",
            title: "Sample Result",
            minResizableWidth: 250,
            width: 250,
        },
        {
            field: "requestHeader",
            title: "Request Header",
            minResizableWidth: 500,
            width: 500,
        },
        {
            field: "requestBody",
            title: "Request Body",
            minResizableWidth: 250,
            width: 500,
        },
        {
            field: "responseHeader",
            title: "Response Header",
            minResizableWidth: 250,
            width: 500,
        },
        {
            field: "responseBody",
            title: "Response Body",
            minResizableWidth: 250,
            width: 500,
        },
    ];
    const correlationTableHeader = [
        {
            field: "_id",
            title: "Time Period",
            minResizableWidth: 150,
            width: 150,
        },
        {
            field: "Count",
            title: "BZM Count",
            minResizableWidth: 100,
            width: 150,
        },
        {
            field: "ErrorGroup",
            title: "BZM Error Code",
            minResizableWidth: 100,
            width: 150,
        },
        {
            field: "FileLine",
            title: "BZM Log",
            minResizableWidth: 250,
            width: 400,
        },
        {
            field: "CountBPI",
            title: "Agent Count",
            minResizableWidth: 100,
            width: 150,
        },
        {
            field: "ErrorGroup_Agent",
            title: "Agent Error Code",
            minResizableWidth: 100,
            width: 150,
        },
        {
            field: "Fileline_Agent",
            title: "Agent Log",
            minResizableWidth: 250,
            width: 500,
        },
    ]

    const style = {
        height: "680px",
    };
    const initialGrouping = [];
    const initialSort = [];
    const initialFilter = {
        logic: "and",
    };
    const initialCorrelationFilter = {
        logic: "and",
        filter: []
    };

    const pageChange = (event) => {
        getSessionIdData(
            apiSecretData,
            showId.selectedSessionId.map((value) => value.value),
            masterFormData,
            sessionId.map((value) => value.value),
            event.skip,
            event.take,
            queryParamsState
        )
            .then((response) => {
                if (response?.data?.status) {
                    let errorTableData = getErrorData(
                        response.data.resultData.masterIdDetails.sessionErrors,
                        showId.selectedSessionId.map((value) => value.value)
                    );
                    setErrorDataTableData((preVal) => ({
                        ...preVal,
                        errorData: errorTableData,
                    }));
                    setSessionIdData(response.data.resultData.data);
                    setLoader(false);
                    setDataCount(response.data.resultData.count);
                    setMasterFormSubmitButtonStatus(false);
                } else {
                    toast.warn(response?.data?.Message);
                    setSessionIdData([]);
                    setLoader(false);
                    setMasterFormSubmitButtonStatus(false);
                }
            })
            .catch((error) => console.log("ERROR", error));
        setDataLimitState(event);
    };

    function debounce(func, timeout) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    function debounceFunction(type, data) {
        let queryParams;
        let selectedSessionId;
        switch (type) {
            case "filter":
                queryParams = data;
                selectedSessionId = showId.selectedSessionId.map(
                    (value) => value.value
                );
                break;
            case "multiSelectSessionId":
                queryParams = queryParamsState;
                let newData = data.map((value) => {
                    return { value: value, checked: true };
                });
                setShowId((preVal) => ({
                    ...preVal,
                    selectedSessionId: newData,
                }));
                selectedSessionId = data;
                break;
            default:
                break;
        }
        let sessionIdArray = sessionId.map((value) => value.value);
        setDataLimitState({ skip: 0, take: 10 });
        getSessionIdData(
            apiSecretData,
            selectedSessionId,
            masterFormData,
            sessionIdArray,
            0,
            10,
            queryParams
        )
            .then((response) => {
                if (response?.data?.status) {
                    let errorTableData = getErrorData(
                        response.data.resultData.masterIdDetails.sessionErrors,
                        selectedSessionId
                    );
                    let flag = true;
                    let finalCount = Object.values(
                        response?.data?.resultData?.masterIdDetails?.sessionErrors
                    ).reduce((cur, next) => {
                        if (!next) {
                            flag = false;
                        }
                        return cur + (next && next.totalCount);
                    }, 0);
                    setErrorDataTableData({
                        totalErrorCount: flag ? finalCount : null,
                        errorData: errorTableData,
                    });
                    setSessionIdData(response.data.resultData.data);
                    setLoader(false);
                    setDataCount(response.data.resultData.count);
                    setMasterFormSubmitButtonStatus(false);
                    toast.success(response?.data?.Message);
                } else {
                    toast.warn(response?.data?.Message);
                    setSessionIdData([]);
                    setLoader(false);
                    setMasterFormSubmitButtonStatus(false);
                }
            })
            .catch((error) => console.log("ERROR", error));
    }

    const processChange = debounce(
        (type, data) => debounceFunction(type, data),
        1500
    );

    const onFilterChange = (event) => {
        let filterQuery = "";
        if (event.filter) {
            event.filter.filters.map((value, index) => {
                filterQuery += `${index === 0 ? "?" : "&"}${value.field}=${value.value
                    }`;
                return null;
            });
            setQueryParamsState(filterQuery);
        } else {
            setQueryParamsState(null);
        }
        processChange("filter", filterQuery);
    };


    const restDatatableData = {
        groupable: true,
        filterable: true,
        pageable: true,
    };
    const selectable = false;

    const handleProfileNameChange = (e) => {
        setApmMatrixFormData((preVal) => ({
            ...preVal,
            labelName: e.target.value,
        }));
    };
    const getSessionIdList = (masterId, selectedScenarioList, scenarioListLength) => {
        let masterForm = document.getElementById("masterIdForm");
        setSelectGrapToggle("graph")
        setDataLimitState({
            skip: 0,
            take: 10,
        });
        setAgentListValue([])
        getAllAgentData()
        setQueryParamsState(null);
        setSessionIdData([]);
        setSessionId([]);
        setLoader(true);
        setShowId({
            masterId: null,
            selectedSessionId: [],
        });
        setMasterFormSubmitButtonStatus(true);
        setMasterFormValidated(false);
        let formData = JSON.parse(localStorage.getItem("apiSecretData"));
        formData.masterId = +masterId;
        formData.scenarioId = selectedScenarioList.map((value) => value.id);
        formData.multiTestStatus = scenarioListLength > 1 ? true : false
        getSessionId(formData)
            .then((response) => {
                if (response) {
                    setNoDataFound(false);
                    setShowId((preVal) => ({
                        ...preVal,
                        masterId: masterId,
                        selectedSessionId: [{ value: response.data[0], checked: true }],
                    }));
                    if (masterForm) {
                        masterForm.reset();
                    }
                    setMasterFormData(masterId);
                    let sessionIdNewList = response.data.map((value, index) => {
                        return { value: value, checked: !index ? true : false };
                    });
                    setSessionId(sessionIdNewList);
                    getSessionIdData(
                        formData,
                        [response.data[0]],
                        masterId,
                        response.data,
                        0,
                        10
                    )
                        .then((sessionIdDataResponse) => {
                            if (sessionIdDataResponse?.data?.status) {
                                let errorTableData = getErrorData(
                                    sessionIdDataResponse.data.resultData.masterIdDetails
                                        .sessionErrors,
                                    [response.data[0]]
                                );
                                let flag = true;
                                let finalCount = Object.values(
                                    sessionIdDataResponse?.data?.resultData?.masterIdDetails
                                        ?.sessionErrors
                                ).reduce((cur, next) => {
                                    if (!next) {
                                        flag = false;
                                    }
                                    return cur + (next && next.totalCount);
                                }, 0);
                                setErrorDataTableData({
                                    totalErrorCount: flag ? finalCount : null,
                                    errorData: errorTableData,
                                });
                                setSessionIdData(sessionIdDataResponse.data.resultData.data);
                                setLoader(false);
                                setDataCount(sessionIdDataResponse.data.resultData.count);
                                setMasterFormSubmitButtonStatus(false);
                                if (sessionIdDataResponse.data.resultData.data.length) {
                                    toast.success(sessionIdDataResponse.data.Message);
                                }
                            } else {
                               // toast.warn(sessionIdDataResponse?.data?.Message);
                                setSessionIdData([]);
                                setLoader(false);
                                setMasterFormSubmitButtonStatus(false);
                            }
                        })
                        .catch((error) => console.log("ERROR 1", error));
                } else {
                    setSessionId([]);
                    setSessionIdData([]);
                    setLoader(false);
                    setNoDataFound(true);
                    setMasterFormSubmitButtonStatus(false);
                }
            })
            .catch((error) => console.log("Error 2", error));
    };
    const exportCSVFunction = () => {
        setCsvButtonStatus(true);
        let selectedSessionIdArray = showId.selectedSessionId.map((value) => value.value)
        getErrorDatatableAllData(apiSecretData, showId.masterId, selectedSessionIdArray)
            .then((res) => {
                setCsvButtonStatus(false);
            })
            .catch((err) => {
                setCsvButtonStatus(false);
            });
    }
    const selectAllSessionId = () => {
        setLoader(true);
        setShowId({ ...showId, selectedSessionId: [] });
        setSessionId([]);
        let data = sessionId.map((value) => value.value);
        processChange(
            "multiSelectSessionId",
            sessionId.length !== showId.selectedSessionId.length ? data : []
        );
        let sessionIdNew = sessionId.map((value) => {
            return {
                value: value.value,
                checked:
                    sessionId.length !== showId.selectedSessionId.length ? true : false,
            };
        });
        setSessionId(sessionIdNew);
    };
    const formatOptionLabel = ({ value, checked }) => (
        <div style={{ display: "flex" }}>
            <div>
                <input
                    type="checkbox"
                    checked={checked}
                    id="sessionIdCheckbox"
                    onChange={() => selectSessionId({ value: value, checked: checked })}
                />
            </div>
            <div className="ms-3">
                <label htmlFor="sessionIdCheckbox">{value}</label>
            </div>
        </div>
    );
    const scenarioOptionLabel = ({ value, checked, id }) => (
        <div style={{ display: "flex" }}>
            <div>
                <input
                    type="checkbox"
                    checked={checked}
                    id="scenarioIdCheckbox"
                    onChange={() => selectScenarioId({ value: value, checked: checked, id: id })}
                />
            </div>
            <div className="ms-3">
                <label htmlFor="scenarioIdCheckbox">{value}</label>
            </div>
        </div>
    );
    const selectScenarioId = (e) => {
        let scenarioIdList = [...scenario.scenarioIdList];
        if (!e.checked) {
            scenarioIdList[scenarioIdList.findIndex((obj) => obj.value === e.value)] = {
                ...e,
                checked: true,
            };
        }
        else {
            scenarioIdList[scenarioIdList.findIndex((obj) => obj.value === e.value)] = {
                ...e,
                checked: false,
            };
        }
        let selectedScenario = scenarioIdList.filter((value) => value.checked === true);
        setScenario({
            scenarioIdList: scenarioIdList,
            selectedScenarioIdList: selectedScenario
        })
        getSessionIdList(+masterFormData, selectedScenario, scenario.scenarioIdList.length)
    }
    const selectSessionId = (e) => {
        let data = [...showId.selectedSessionId];
        let sessionIds = [...sessionId];
        if (!e.checked) {
            data.push({ value: e.value, checked: true });
            sessionIds[sessionIds.findIndex((obj) => obj.value === e.value)] = {
                ...e,
                checked: true,
            };
        } else {
            data.splice(
                data.findIndex((obj) => obj.value === e.value),
                1
            );
            sessionIds[sessionIds.findIndex((obj) => obj.value === e.value)] = {
                ...e,
                checked: false,
            };
        }
        let finalData = data.map((value) => value.value);
        processChange("multiSelectSessionId", finalData);
        setSessionId([...sessionIds]);
    };

    const [workspaceDataList, setWorkspaceDataList] = useState();
    // setWorkSpace(workSpaceData.workSpaceId.name)
    // console.log("workSpaceId",workSpace)
    

    useEffect(() => {
        // setWorkspaceLoader(true);
        setWorkspaceDataList();
        let creads = localStorage.getItem("apiSecretData");
        if (creads && workSpaceData.workSpaceIdArray.length > 0) {
            let preUsedWorkspaceId = JSON.parse(localStorage.getItem("workspaceId"));
            getUserDetails(JSON.parse(creads))
                .then((response) => {
                    var userEmailId = CryptoJS.AES.encrypt(
                        response.data.result.email,
                        process.env.REACT_APP_ENCRYPTION_KEY
                    ).toString()
                    localStorage.setItem("emailId", userEmailId);
                    setUserDetails(response.data.result);
                    getWorkSpaceIdList(
                        JSON.parse(creads),
                        response?.data?.result?.defaultProject?.accountId
                    )
                        .then((response) => {
                            if (!preUsedWorkspaceId) {
                                localStorage.setItem(
                                    "workspaceId",
                                    JSON.stringify(response.data[0])
                                );
                            }
                            getMasterIdList(
                                JSON.parse(creads),
                                preUsedWorkspaceId ? preUsedWorkspaceId.id : response.data[0].id
                            )
                                .then((masterIdListResponse) => {
                                    let workSpaceData = {
                                        workSpaceIdArray: response?.data,
                                        workSpaceId: preUsedWorkspaceId
                                            ? preUsedWorkspaceId
                                            : response?.data[0],
                                        masterIdList: masterIdListResponse?.data,
                                        currentMasterId: masterIdListResponse?.data[0]?.id,
                                    };
                                    setWorkspaceDataList(workSpaceData)
                                    let creads = JSON.parse(localStorage.getItem("apiSecretData"));
                                    creads.masterId = masterIdListResponse?.data[0]?.id;
                                    getScenarioId(creads).then((response) => {
                                        // let scenarioIdData = response.data.scenariosMapping === undefined ? response.data.label : response.data.scenariosMapping;
                                        let scenarioIdListArray = [];
                                        if (response.data.scenariosMapping != undefined) {
                        
                                            scenarioIdListArray = response.data.scenariosMapping.map((value, index) => {
                                                return { value: value.name, checked: true, id: value.id };
                                            })
                                        } else {
                                            scenarioIdListArray = response.data.labels.map((value, index) => {
                                                return { value: value.name, checked: true, id: value.id };
                                            })
                                        }
                                        // let scenarioIdListArray = scenarioIdData.map((value, index) => {
                                        //     return { value: value.name, checked: true, id: value.id };
                                        // })
                                        setScenario({
                                            selectedScenarioIdList: scenarioIdListArray,
                                            scenarioIdList: scenarioIdListArray
                                        })
                                        getSessionIdList(masterIdListResponse.data[0].id, scenarioIdListArray, scenarioIdListArray.length)
                                        let preSelectedArray = ['fail', 'unset']
                                        let finalData = masterIdListResponse.data.filter((value) => preSelectedArray.includes(value.reportStatus))
                                        setSelectedTestFilterData(finalData)
                                    })

                                })
                                .catch((error) =>
                                    console.log("Master id list response error", error)
                                );
                        })
                })
                .catch((error) => {
                    setModalStatus(true);
                });
            setShowLinks(true);
        } else {
            setShowLinks(false);
        }

    }, [workSpaceData]);

    const changeWorkSpaceData = (workspaceObject) => {
        localStorage.setItem("workspaceId", JSON.stringify(workspaceObject));
        setWorkspaceDataList({
            ...workspaceDataList,
            showWorkSpace: true
        });
        let creads = localStorage.getItem("apiSecretData");
        getMasterIdList(JSON.parse(creads), workspaceObject.id)
            .then((masterIdListResponse) => {
                let workSpaceDataList = {
                    workSpaceIdArray: workspaceDataList.workSpaceIdArray,
                    workSpaceId: workspaceObject,
                    masterIdList: masterIdListResponse.data,
                    currentMasterId: masterIdListResponse?.data[0]?.id,
                    showWorkSpace: false
                };
                setWorkspaceDataList(workSpaceDataList);
            })
            .catch((error) =>
                console.log("Master id list response error", error.message)
            );
    };
    let statusObject = {
        noData: "No Data",
        pass: "Pass",
        fail: "Fail",
        unset: "Not Set",
        error: "Error",
        abort: "Abort",
    };

    const testListFilter1 =
        [
            "Pass",
            "Fail",
            "Not Set",
            "Error",
            "No Data",
            "Abort"
        ]

    const [defaultSelectValue, setDefaultSelectValue] = useState(["Not Set", "Fail"])
    const [selectedTestFilterData, setSelectedTestFilterData] = useState([]);

    const selectTestListFilter = (e) => {
        if (e.length > 0) {
            let selectedFilter = e.map((value) => {
                if (value === 'Not Set') {
                    return "unset"
                } else if (value === "No Data") {
                    return "noData"
                }
                else {
                    return value.toLowerCase();
                }
            }
            );
            let finalData = workspaceDataList.masterIdList.filter((value) => selectedFilter.includes(value.reportStatus))

            setSelectedTestFilterData(finalData)
        } else {
            setSelectedTestFilterData(workspaceDataList.masterIdList);
        }
    }
    const selectGraphToggleView = () => {
        setSelectGrapToggle("table");
    }

    const selectTableToggleView = () => {
        setSelectGrapToggle("graph");
    }

    const selectCorrelationTable = () => {
        setSelectGrapToggle("correlationTable")
    }

    React.useEffect(() => {
        masterFormData && setMasterFormData(workspaceDataList?.masterIdList[0]?.id);
        const getAgent = [];
        getAgent.push({ value: "Create New Agent", label: "+ Create New Agent" });
        setGetAllAgent(getAgent);
    }, []);

    const rapText = (id) => {
        var element = document.getElementById(id);
        element.style.removeProperty("white-space");
    }
    const noRapText = (id) => {
        document.getElementById(id).style.whiteSpace = "nowrap"
    }

    const [emptySlectValue, setEmptySlectValue] = useState("");
    const recentTestFilterByTestName = (testName) => {
        setEmptySlectValue(testName);
        let finalData = workspaceDataList.masterIdList.filter((value) => {
            if (testName === "") {
                return value;
            } else if (value.name.toLowerCase().includes(testName.toLowerCase())) {
                return value;
            }
        })
        setSelectedTestFilterData(finalData);
    }

    const selectAllScenarioId = () => {
        let newScenarioData;
        if (scenario.scenarioIdList.length === scenario.selectedScenarioIdList.length) {
            newScenarioData = scenario.scenarioIdList.map((value) => { return { ...value, checked: false } })
            setScenario({
                scenarioIdList: newScenarioData,
                selectedScenarioIdList: []
            })
        }
        else {
            newScenarioData = scenario.scenarioIdList.map((value) => { return { ...value, checked: true } })
            setScenario({
                scenarioIdList: newScenarioData,
                selectedScenarioIdList: newScenarioData
            })
        }

        getSessionIdList(+masterFormData, [], newScenarioData)
    }

    let filterValue = document.getElementById("search_input");
    let searchFiledValue;
    const getSearchValue = () => {
        var filterValue = document.getElementById("search_input")?.value;
        searchFiledValue = filterValue;
        document.getElementById("search_input").value = filterValue;
    }
    const setSearchValue = () => {
        if(searchFiledValue != undefined && searchFiledValue!='')
        {
        
            setDefaultSelectValue([...defaultSelectValue, searchFiledValue])
        }
       
    }
    useEffect(() => {
        filterValue?.addEventListener("keypress", getSearchValue);
        filterValue?.addEventListener("blur", setSearchValue);
    }, [filterValue])

    const getScenarioData = (masterId, selectedScenarioList, scenarioListLength) => {

        let creads = JSON.parse(localStorage.getItem("apiSecretData"));
        creads.masterId = +masterId;
        getScenarioId(creads).then((response) => {

            // let scenarioIdListArray = response.data.scenariosMapping.map((value, index) => {
            //     return { value: value.name, checked: true, id: value.id };
            // })
            let scenarioIdListArray = [];
            if (response.data.scenariosMapping != undefined) {
                
                scenarioIdListArray = response.data.scenariosMapping.map((value, index) => {
                    return { value: value.name, checked: true, id: value.id };
                })
            } else {
            
                scenarioIdListArray = response.data.labels.map((value, index) => {
                    return { value: value.name, checked: true, id: value.id };
                })
            }
            setScenario({
                scenarioIdList: scenarioIdListArray,
                selectedScenarioIdList: scenarioIdListArray
            })
            getSessionIdList(+masterId, scenarioIdListArray, scenarioIdListArray.length);
        })
    }





    return (
        <>
            <div class="mt-2 container-fluid">
                <ToastContainer />
                <div class="row" >
                    <div class="col-3" style={{ backgroundColor: '#ffffff', border: '1px solid #f4f6f9' }}>
                        <div style={{ marginTop: '11px' }}>
                            <div style={{ marginTop: '11px' }}>
                                <label className='mb-2 small fw-bold'>Filter by Test Report Status</label>
                                <Multiselect
                                    isObject={false}
                                    options={testListFilter1}
                                    onSearch={(e) => recentTestFilterByTestName(e)}
                                    onSelect={(e) => selectTestListFilter(e)}
                                    onRemove={(e) => selectTestListFilter(e)}
                                    placeholder="Search by test name"
                                    selectedValues={defaultSelectValue}
                                    style={{ chips: { background: "#106dfa" } }}
                                    emptyRecordMsg={emptySlectValue}
                                />
                            </div>
                        </div>
                        <div className='mt-3'>
                            {workspaceDataList ?
                                <div className="masterTableBodyDivision1 p-0">
                                    {
                                        selectedTestFilterData.length !== 0 && selectedTestFilterData.map((headerBody, index) => {
                                            return (
                                                <>{
                                                    // (headerBody.reportStatus === "unset" || headerBody.reportStatus === "fail") &&
                                                    <ul
                                                        key={"body" + index}
                                                        className="masterTableBody1 border-bottom mb-0 py-1"
                                                        style={{ backgroundColor: ((headerBody.id === (masterFormData === "" ? workspaceDataList?.masterIdList[0]?.id : masterFormData))) ? '#EDF6FF' : '', boxShadow: ((headerBody.id === (masterFormData === "" ? workspaceDataList?.masterIdList[0]?.id : masterFormData))) ? 'rgb(90 120 240) -6px 0px inset' : '', marginLeft: '3px' }}
                                                    >
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <li className="text-center d-flex justify-content-left align-items-left">
                                                                    <div
                                                                        className={`${Object.keys(statusObject).find(
                                                                            (key) => key === headerBody.reportStatus
                                                                        )} statusCommonCss1`}
                                                                        style={{ marginRight: '6px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                                        {statusObject[headerBody.reportStatus]}
                                                                    </div>
                                                                </li>
                                                            </div>
                                                            <div className='col-4'>
                                                                <li
                                                                    // onClick={() => getSessionIdList(headerBody.id, scenario.selectedScenarioIdList, scenario.scenarioIdList.length)}
                                                                    onClick={() => getScenarioData(headerBody.id, scenario.selectedScenarioIdList, scenario.scenarioIdList.length)}
                                                                    style={{ cursor: "pointer", color: "#0d6efd" }}
                                                                    className="text-center d-flex justify-content-left align-items-left"
                                                                >
                                                                    <div id={headerBody.id} style={{ wordBreak: "break-word", marginRight: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onMouseOver={() => rapText(headerBody.id)} onMouseOut={() => noRapText(headerBody.id)}>{headerBody.name} ({headerBody.id})</div>
                                                                </li>
                                                            </div>
                                                            <div className='col-4'>
                                                                <li>
                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                        {moment().diff(
                                                                            moment(Number(headerBody.created) * 1000),
                                                                            "minutes"
                                                                        ) < 60
                                                                            ? `${moment().diff(
                                                                                moment(Number(headerBody.created) * 1000),
                                                                                "minutes"
                                                                            )} minutes ago`
                                                                            : moment().diff(
                                                                                moment(Number(headerBody.created) * 1000),
                                                                                "hours"
                                                                            ) < 24
                                                                                ? `${moment().diff(
                                                                                    moment(Number(headerBody.created) * 1000),
                                                                                    "hours"
                                                                                )} hours ago`
                                                                                : moment().diff(
                                                                                    moment(Number(headerBody.created) * 1000),
                                                                                    "days"
                                                                                ) < 31
                                                                                    ? `${moment().diff(
                                                                                        moment(Number(headerBody.created) * 1000),
                                                                                        "days"
                                                                                    )} days ago`
                                                                                    : moment().diff(
                                                                                        moment(Number(headerBody.created) * 1000),
                                                                                        "month"
                                                                                    ) < 12
                                                                                        ? `${moment().diff(
                                                                                            moment(Number(headerBody.created) * 1000),
                                                                                            "month"
                                                                                        )} month ago`
                                                                                        : `${moment().diff(
                                                                                            moment(Number(headerBody.created) * 1000),
                                                                                            "year"
                                                                                        )} Year ago`}
                                                                    </div>
                                                                </li>

                                                            </div>
                                                        </div>
                                                    </ul>
                                                }
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div> :
                                <div className='text-center'>
                                    <Loader
                                        themeColor={"dark"}
                                        size="medium"
                                        type="converging-spinner"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-9" style={{
                        backgroundColor: '#ffffff', border: '1px solid #f4f6f9', width: "73%",
                        marginLeft: "1%"
                    }}>
                        <div className='row mt-3'>
                            <Form
                                noValidate
                                validated={masterFormValidated}
                                onSubmit={(e) => handleSubmit(e)}
                                id="masterIdForm"
                            >
                                <div class="row mb-4">
                                    <div class="col-2">
                                        <div class="form-outline">
                                            <label className='mb-1 small fw-bold'>Enter Master ID </label>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationCustom01"
                                            >
                                                <Form.Control
                                                    name="masterId"
                                                    required
                                                    type="text"
                                                    defaultValue={masterFormData === "" ? workspaceDataList?.masterIdList[0]?.id : masterFormData}
                                                    autoComplete="off"
                                                    placeholder="Enter Master ID"
                                                    onChange={(e) => handleChange(e, "masterForm")}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div class="form-group mb-2 col-3">
                                        <label className='mb-1 small fw-bold'>
                                            <span>Scenario</span> {" "}
                                            <span>({`${scenario.selectedScenarioIdList.length} / ${scenario.scenarioIdList.length}`})</span>
                                            {
                                                scenario.scenarioIdList.length > 1 ?

                                                    <span style={{ marginLeft: '10px' }}>
                                                        {
                                                            sessionId != '' &&
                                                            <>
                                                                <input
                                                                    type={"checkbox"}
                                                                    className="text-dark"
                                                                    id={`default-${`type`}`}
                                                                    checked={
                                                                        scenario.scenarioIdList.length === scenario.selectedScenarioIdList.length
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => selectAllScenarioId()}
                                                                    style={{ marginRight: '3px' }}
                                                                />
                                                                <span>
                                                                    {
                                                                        scenario.scenarioIdList.length === scenario.selectedScenarioIdList.length
                                                                            ? `Remove All`
                                                                            : `Select All`
                                                                    }
                                                                </span>
                                                            </>
                                                        }
                                                    </span>
                                                    : null
                                            }
                                        </label>
                                        <Select
                                            formatOptionLabel={scenarioOptionLabel}
                                            options={scenario.scenarioIdList}
                                            value={scenario.scenarioIdList[0]}
                                            isDisabled={scenario.scenarioIdList.length > 1 ? false : true}
                                        />
                                    </div>
                                    <div class="form-group mb-2 col-3">
                                        <label className='mb-1 small fw-bold'>
                                            <span>Engine Id</span> {" "}
                                            <span>{sessionId != '' && `(${showId.selectedSessionId.length} / ${sessionId.length}) `}</span>
                                            <span style={{ marginLeft: '10px' }}>
                                                {
                                                    sessionId != '' && sessionId.length > 1 &&
                                                    <>
                                                        <input
                                                            type={"checkbox"}
                                                            className="text-dark"
                                                            id={`default-${`type`}`}
                                                            checked={
                                                                sessionId.length ===
                                                                    showId.selectedSessionId.length
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => selectAllSessionId()}
                                                            style={{ marginRight: '3px' }}
                                                        />
                                                        <span>
                                                            {
                                                                sessionId.length ===
                                                                    showId.selectedSessionId.length
                                                                    ? `Remove All`
                                                                    : `Select All`
                                                            }
                                                        </span>
                                                    </>
                                                }
                                            </span>
                                        </label>
                                        <Select
                                            formatOptionLabel={formatOptionLabel}
                                            options={sessionId}
                                            value={sessionId[0]}
                                            isDisabled={sessionId.length > 1 ? false : true}
                                        />

                                    </div>
                                    <div class="form-group col-2">
                                        <label className='mb-1 small fw-bold'>
                                            Agent Id
                                        </label>
                                        <Select
                                            options={getAllAgent}
                                            onChange={(e) => selectAgentHandler(e)}
                                            value={agentListValue}
                                            isMulti
                                        />
                                    </div>
                                    <div class="col-2">
                                        <div className='row d-flex align-items-end'>
                                            <div class="form-outline col-8 text-center p-0">
                                                <Button
                                                    style={{ marginTop: '27px' }}
                                                    type="submit"
                                                    disabled={masterFormSubmitButtonStatus}
                                                    className="w-75"
                                                >
                                                    <span>Submit</span>
                                                </Button>
                                            </div>
                                            <div className='col-4'>
                                                <div className='col-4 p-0 d-flex justify-content-center align-items-center'>
                                                    <i class="bi bi-speedometer d-flex justify-content-center align-items-center" id="gicon" style={{ fontSize: '35px', cursor: 'pointer', color: "#106dfa" }} onClick={handleImportPerfmonMetrics}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {sessionIdData.length > 0 &&
                            <div className='float-right'>
                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => selectTableToggleView()} style={{ cursor: 'pointer', backgroundColor: selectGrapToggle === "graph" ? "#6c757d" : "", color: selectGrapToggle === "graph" ? "white" : "" }}>Grid</button>
                                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => selectGraphToggleView()} style={{ cursor: 'pointer', backgroundColor: selectGrapToggle === "table" ? "#6c757d" : "", color: selectGrapToggle === "table" ? "white" : "" }}>Chart</button>
                                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => selectCorrelationTable()} style={{ cursor: 'pointer', backgroundColor: selectGrapToggle === "correlationTable" ? "#6c757d" : "", color: selectGrapToggle === "correlationTable" ? "white" : "" }}>Summary</button>
                                </div>
                            </div>
                        }
                        <div className="row mt-3">
                            <div>
                                <Datatable
                                    initialData={sessionIdData}
                                    headerData={
                                        datatableHeader.map((obj) => ({
                                            field: obj.field,
                                            title: obj.title,
                                            width: 150,
                                        }))
                                    }
                                    style={style}
                                    initialGrouping={initialGrouping}
                                    initialSort={initialSort}
                                    initialFilter={initialFilter}
                                    initialDataState={dataLimitState}
                                    loader={loader}
                                    restDatatableData={restDatatableData}
                                    selectable={selectable}
                                    onPageChange={(event) => pageChange(event)}
                                    pageSize={pageSize}
                                    dataCount={dataCount}
                                    filterChange={(event) => onFilterChange(event)}
                                    exportCSVFunction={(data) => exportCSVFunction(data)}
                                    csvButtonStatus={csvButtonStatus}
                                    masterFormData={masterFormData}
                                    selectedAgentId={selectedAgentId}
                                    selectGraph={selectGrapToggle}
                                    errorDataTableData={errorDataTableData}
                                    correlationTableHeader={correlationTableHeader}
                                    initialCorrelationFilter={initialCorrelationFilter}
                                ></Datatable>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Modal
                show={importPerfmonMetricsModal}
                onHide={handleImportPerfmonMetricsClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Perfmon Connector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="apiMatrixForm">
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Master Id</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={apmMatrixFormData.masterId}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="">
                                <Form.Group
                                    className="mb-3 w-100 "
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Profile Name</Form.Label>
                                    <div className="d-flex align-items-center position-relative p-0">
                                        <Form.Control
                                            type="text"
                                            value={apmMatrixFormData.labelName}
                                            onChange={(e) => {
                                                handleProfileNameChange(e);
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Start Time</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={apmMatrixFormData.startTime}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>End Time</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={apmMatrixFormData.endTime}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Select Files</Form.Label>
                            <Form.Control
                                type="file"
                                name="filesArray"
                                required
                                multiple
                                accept=".csv"
                                onChange={(e) => handleFilesChange(e)}
                            />
                        </Form.Group>
                    </Form>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            className="fontStyle minWidth"
                            disabled={apmMatrixFormData.submitButtonStatus}
                            onClick={() => handleImportMetricFromSubmit()}
                        >
                            {/* Submit */}
                            {apmMatrixFormData.submitButtonLoaderStatus ? (
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </div>
                    {apmMatrixData.data.length ? (
                        <>
                            <MatrixTableComponent
                                apmMatrixData={apmMatrixData}
                                selectAll={(parentKey) => selectAll(parentKey)}
                                selectCheckboxData={(parentKey, childKey) =>
                                    selectCheckboxData(parentKey, childKey)
                                }
                                handleApmMatrixData={(value) => handleApmMatrixData(value)}
                            />
                            <div className="my-3 w-100 d-flex">
                                <Button
                                    type="button"
                                    onClick={handleModalSubmit}
                                    disabled={
                                        apmMatrixData.data.length !==
                                        Object.keys(apmMatrixData.objectOfId).length ||
                                        apmMatrixData.submitButtonLoaderStatus
                                    }
                                    className="ms-auto col-3"
                                >
                                    <span className="fontStyle">
                                        {apmMatrixData.submitButtonLoaderStatus ? (
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                            ></Spinner>
                                        ) : (
                                            "Submit"
                                        )}
                                    </span>
                                </Button>
                            </div>
                        </>
                    ) : link ? (
                        <div className="my-5 d-flex justify-content-center">
                            You can check BlazeMeter Report wih Perfmon data Here :
                            &nbsp;&nbsp;{" "}
                            <a href={link} target="_blank" style={{ textDecoration: "none" }}>
                                {link}
                            </a>
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        className="fontStyle"
                        onClick={() => handleImportPerfmonMetricsClose()}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
