import { Badge, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import "../../style/Alert.css";
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { getCoreleationLogData } from '../../apis/LogMonitor'
import { getTimeStampDetails } from '../../apis/ErrorAnalysis'
import { Loader } from "@progress/kendo-react-indicators";
export const AlertBootstrap = ({ errorDataArray, selectedAgentId, masterId, sessionData }) => {
  let { totalErrorCount, errorData } = errorDataArray;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCorelationData([]);
    setShow(false);
  }
  const [corelationData, setCorelationData] = useState([]);
  const corelationHandler = (errorCode) => {
    const Keyword = [];
    Keyword.push(errorCode)
    let timeStampDataObject = {};
    let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
    timeStampDataObject["apiKey"] = apiSecretData.key;
    timeStampDataObject["apiSecret"] = apiSecretData.secret;
    timeStampDataObject["masterId"] = masterId
    if (selectedAgentId.length > 0) {
      setShow(true);
      getTimeStampDetails(timeStampDataObject).then((response) => {
        getCoreleationLogData(response.data.data.created, response.data.data.ended, selectedAgentId, Keyword).then((response) => {
          setCorelationData(...corelationData, response.data.LogDataList.filter(logData => logData.ErrorGroup === errorCode));
          let filterData = [];
          corelationData.LogDataList.map((data) => {
            sessionData.map((sessionDatas) => {
              // const fileLogTime = new Date(`${data.FileLogTime}`).getTime();
              const fileLogTime = data.UnixTimestamp;
              const blazemeterTime = new Date(`${sessionDatas.timeStamp}`).getTime();
              if ((blazemeterTime + 300000) < fileLogTime || (blazemeterTime - 300000) > fileLogTime) {
                filterData.push(data);
              }
            })
          })
          setCorelationData(filterData);
        })
      })
    }
  }
  return (
    <Alert
      key={"secondary"}
      variant={"secondary"}
      className="position-relative"
    >
      <div className="mb-3">
        <div className="text-dark fw-bolder fs-5 ps-1">
          Error Count Details
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div
          style={{
            overflowY: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          className="alertDivision m-auto px-3 pb-1"
        >
          {totalErrorCount ? (
            <Button
              variant="primary"
              className="mx-1 my-1 btn-outline-secondary bg-white errorCountButton"
            >
              <div className="fw-bold text-dark py-1 border-bottom">
                Total Error Count
              </div>
              <div className="py-2">
                <Badge bg="secondary">
                  <span className="bg-secondary">{totalErrorCount}</span>
                </Badge>
              </div>
            </Button>
          ) : null}

          {errorData.map((error, index) => {
            return (
              <Button
                variant="primary"
                className="mx-1 my-1 btn-outline-secondary bg-white errorCountButton"
                id={`errorButtonId${index}`}
                key={"errorData" + index}
                onClick={() => corelationHandler(error.errorCode)}
              >
                <div className="fw-bold text-dark py-1 border-bottom">
                  Error Code : {error.errorCode}
                </div>
                <div className="py-2">
                  <Badge bg="secondary">
                    <span className="bg-secondary">{error.errorCount}</span>
                  </Badge>
                </div>
              </Button>
            );
          })}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        {corelationData.length > 0 ?
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center', width: '100px' }}>Error Code</th>
                  <th style={{ textAlign: 'center', width: '121px' }}>Time Window</th>
                  <th style={{ textAlign: 'center' }}>Count</th>
                  <th style={{ textAlign: 'center', width: '149px' }}>BlazeMeter Error</th>
                  <th style={{ textAlign: 'center' }}>Application Error</th>
                </tr>
              </thead>
              <tbody>
                {
                  corelationData?.map(log => {
                    return (
                      <tr>
                        <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.ErrorGroup}</td>
                        <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.FileLogStartTime} - {log.FileLogEndTime}</td>
                        <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.Count}</td>
                        <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{sessionData[0].sampleResult}</td>
                        <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.FileLine}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>

          </Modal.Body>
          :
          <div class="text-center m-5">
            <Loader
              themeColor={"dark"}
              size="medium"
              type="converging-spinner"
            />
          </div>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Alert>
  );
};