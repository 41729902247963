import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ApiAndSecretForm } from "../components/creds_info/ApiAndSecretForm";
import "../style/Homepage.css";
import logo from "../assets/images/fav_Icon.jpg";

export const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <div className="homepage d-flex justify-content-center align-items-center">
        <div className="homePageContainer bg-white py-5 rounded">
          <div className="py-4 fw-bold fs-1 d-flex justify-content-center align-items-center">
            <img
              src={logo}
              className="me-3"
              style={{ fontSize: "25px" }}
              height="50px"
              width="50px"
              alt="logo"
            />
            <span className="welcomeText">Welcome </span>
          </div>
          <div className="py-2 d-flex justify-content-center align-items-center fs-3 subtitle1">
            Achieve Deeper Visibility with Blaze Performance Insights
          </div>
          <div className="py-2 d-flex align-items-center fs-5  w-50 m-auto ">
            <ul>
              <li className="py-1">
                Insights into Errors in your BlazeMeter Performance Tests
              </li>
              <li className="py-1">
                Easier identification of bottlenecks by merging your Server-Side
                Metrics into BlazeMeter
              </li>
            </ul>
          </div>
          <div className="py-4 d-flex justify-content-center align-items-center">
            <Button onClick={handleModal}>Get Started</Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>BlazeMeter Connection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApiAndSecretForm
            setShowApiSecretForm={setShowModal}
          ></ApiAndSecretForm>
        </Modal.Body>
      </Modal>
    </>
  );
};
