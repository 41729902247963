import * as React from "react";
import "../../style/Datatable1.css";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from "@progress/kendo-react-grid";
import { groupBy, orderBy, filterBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Loader } from "@progress/kendo-react-indicators";

import { ExcelExport } from "@progress/kendo-react-excel-export";
import { LineChart, Line, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Label, ResponsiveContainer, ReferenceLine } from 'recharts';
import { getCoreleationLogData, getBlazemeterAggregateData } from '../../apis/LogMonitor'
import { getTimeStampDetails } from '../../apis/ErrorAnalysis'
import moment from "moment";
import NoData from "../../assets/images/nodata.jpg"
import "@progress/kendo-theme-default/dist/all.css";
const CustomHeader = (props) => {
  return (
    <div className="d-flex justify-content-center fontStyle">{props.title}</div>
  );
};

const CustomCorrelationHeader = (props) => {
  return (
    <div className="d-flex justify-content-center fontStyle" style={{height:"20px"}}>{props.title}</div>
  );
}

const style = {
  verticalAlign: "top",
};
const CustomCell = (props) => {
  const field = props.field || "";
  return <td style={style}>{props.dataItem[field]}</td>;
};
const CustomCorrelationCell = (props) => {
  const field = props.field || "";
  return <td style={style}>{props.dataItem[field]?props.dataItem[field]:"-"}</td>;
};

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

export const Datatable = ({
  initialData,
  headerData,
  style,
  initialGrouping,
  initialSort,
  initialFilter,
  initialDataState,
  loader,
  restDatatableData,
  onPageChange,
  dataCount,
  filterChange,
  exportCSVFunction,
  csvButtonStatus,
  masterFormData,
  selectedAgentId,
  selectGraph,
  errorDataTableData,
  correlationTableHeader,
  initialCorrelationFilter
}) => {
  
  const _export = React.useRef(null);
  const [blazemeterAggregateData, setBlazemeterAggregateData] = React.useState([]);
  const excelExport = () => {
    exportCSVFunction();
  };
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    onPageChange(event.page);
    setPage(event.page);
  };
  const correlationPageChange = (event) => {
    setPage(event.page);
  };
  const [filter, setFilter] = React.useState(initialFilter);

  const [sort, setSort] = React.useState(initialSort);
  const [initialGroupingData, setInitialGroupingData] =
    React.useState(initialGrouping);
  const [group, setGroup] = React.useState(initialGroupingData);
  const [resultState, setResultState] = React.useState(
    processWithGroups(initialData, initialGroupingData)
  );

  const [collapsedState, setCollapsedState] = React.useState([]);
  const onGroupChange = React.useCallback(
    (event) => {
      const newDataState = processWithGroups(initialData, event.group);
      setInitialGroupingData(event.group);
      setGroup(event.group);
      setResultState(newDataState);
    },
    [initialData]
  );
  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );
  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });
  const onSortChange = React.useCallback(
    (event) => {
      if (event.sort.length) {
        let data = orderBy(newData, event.sort);
        setResultState(data);
      } else {
        setResultState(initialData);
      }
      setSort(event.sort);
    },
    [newData, initialData]
  );
  const onFilterChange = React.useCallback(
    (event) => {
      if (event) {
        filterChange(event);
        setFilter(event.filter);
      } else {
        setFilter(event.filter);
      }
    },
    [initialData]
  );

  // const onCorrelationFilterChange = React.useCallback(
  //   (event) => {
  //     selectCorrelationFilter(event.filter);
  //   },
  //   []
  // );

  React.useEffect(() => {
    setResultState(processWithGroups(initialData, initialGroupingData));
    setPage({ skip: initialDataState.skip, take: initialDataState.take });
  }, [initialData, initialGroupingData, initialDataState]);

  const [corelationData, setCorelationData] = React.useState([]);
  const [graphLoader, setGraphLoader] = React.useState(false);
  var d = []

  const checkGraphToggle = () => {
    if (masterFormData != '' && selectedAgentId.length > 0) {
      setGraphLoader(false)
      setCorelationData([]);
      const Keyword = [];
      errorDataTableData.errorData.map((errorcode) => {
        Keyword.push(errorcode.errorCode);
      })
      let timeStampDataObject = {};
      let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
      timeStampDataObject["apiKey"] = apiSecretData.key;
      timeStampDataObject["apiSecret"] = apiSecretData.secret;
      timeStampDataObject["masterId"] = masterFormData;
      getTimeStampDetails(timeStampDataObject).then((response) => {
        getCoreleationLogData(response.data.data.created, response.data.data.ended, selectedAgentId, Keyword).then((corelationResponse) => {
          for (let index = 0; index < corelationResponse.data.LogDataList.length; index++) {
            let blazeDateTime = moment(corelationResponse.data.LogDataList[index].UnixTimestamp, "X").toISOString();
            let firstTime = moment(blazemeterAggregateData[index]._id);
            let secondTime = moment(blazeDateTime);
            if (Math.abs(firstTime.diff(secondTime, "minutes")) <= 3) {
              const newData = {
                _id: blazemeterAggregateData[index]._id,
                ErrorGroup_Agent: corelationResponse.data.LogDataList[index].ErrorGroup,
                CountBPI: corelationResponse.data.LogDataList[index].Count,
                // Fileline_Agent : String(corelationResponse.data.LogDataList[index].FileLine).slice(1, 100) + ' ...'}
                Fileline_Agent: String(corelationResponse.data.LogDataList[index].FileLine)
              }
              d.push(newData)
              setCorelationData(d);
            }
          }
          let merged = [];
          for (let i = 0; i < blazemeterAggregateData.length; i++) {
            merged.push({
              ...blazemeterAggregateData[i],
              ...(d.find((itmInner) => itmInner._id === blazemeterAggregateData[i]._id))
            }
            );
          }
          setBlazemeterAggregateData(merged);
          setGraphLoader(false);
        })
      })
    }
  }

  React.useEffect(() => {
    selectedAgentId.length !== 0 && checkGraphToggle();
    if (selectedAgentId.length == 0) {
      setCorelationData([])
      if (initialData.length > 0) {
        getBlazemeterAggregateData(masterFormData).then((response) => {
          let aggregatedData = response.data.map((value,index) => {return {...value,_id:moment(value._id).format('MMMM Do YYYY, h:mm:ss a')
          }})
          setBlazemeterAggregateData(aggregatedData);
        })
      }
      selectGraph = "table";
    }
  }, [selectedAgentId]);

  const [graphLoaderData, setGraphLoaderData] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  React.useEffect(() => {
    setGraphLoaderData(false);
    setBlazemeterAggregateData([])
    setShowMessage(false);
    if (masterFormData) {
      getBlazemeterAggregateData(masterFormData).then((response) => {
        // let aggregatedData = response.data.map((value,index) => {return {...value,_id:moment(value._id).format('MMMM Do YYYY, h:mm:ss a')}})
        setBlazemeterAggregateData(response.data);
      })
    }
    else {
      setShowMessage(true);
    }
  }, [initialData])

  React.useEffect(() => {
    setGraphLoaderData(true);
  }, [masterFormData])

  function formatXAxis(tickItem) {
    // return tickItem?.split(",")[1];
    return moment(tickItem).format('LTS')
  }

  const [dataFormat, setDataFormat] = React.useState(true);
  const [dateShow, setDateShow] = React.useState('');
  function xAxisHover(tickItem) {
    setDataFormat(false)
    setDateShow(moment(tickItem).format('L'));
  }

  function xAxisOut(tickItem) {
    setDataFormat(true)
    setDateShow('');
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ inlineSize: "700px", overflowWrap: "break-word", border: '1px', backgroundColor: "#f8f9fa" }}>
          <div>{moment(label).format('MMMM Do YYYY, h:mm:ss a')}</div>
          {
            payload.map((data) => {
              return (
                <div>{data.name} : {String(data.value).length > 100 ? String(data.value).slice(0, 100) + ' ...' : String(data.value)}</div>
              )
            })
          }
        </div>
      );
    }
    return null;
  };
  
  return (
    <>
      {
        graphLoader ? (
          <>
            {<div
              style={style}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <Loader
                themeColor={"dark"}
                size="medium"
                type="converging-spinner"
              />
              &nbsp;&nbsp;
              <div>Please Wait...</div>
            </div>}
          </>
        ) : (selectGraph === "table" ?
          (graphLoaderData ?
            <div class="text-center">
              <Loader
                themeColor={"dark"}
                size="medium"
                type="converging-spinner"
              />
            </div>
            :
            <div>
              {initialData.length === 0  ?
                <div class="empty-state">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <img src={NoData} alt="No Data Found" width={200} height={200} />
                    </div>
                    <div class="empty-state__message">Yay, your test doesn't have any errors !</div>
                  </div>
                </div>
                :
                <div>
                  <LineChart width={1250} height={600} data={blazemeterAggregateData} margin={{ top: 10, right: 0, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="_id" style={{ fontWeight: "bolder", fontSize: "12px" }} tickFormatter={formatXAxis} onMouseOver={xAxisHover} onMouseOut={xAxisOut}>
                      <Label value={dataFormat ? "Time Period" : "Time Period (" + dateShow + ")"} offset={0} position="bottom" />
                    </XAxis>
                    <YAxis dataKey="Count" style={{ fontWeight: "bolder", fontSize: "12px" }} label={{ value: 'Error Count', angle: -90, position: 'insideLeft' }} />
                    {/* <Tooltip contentStyle={{ outLine: "None"}} /> */}
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Line type="monotone" name="BZM Count" dataKey="Count" stroke="red" fillOpacity={1} fill="url(#colorUv)" />
                    <Line type="monotone" name="BZM Error Code" dataKey="ErrorGroup" stroke="none" fillOpacity={1} fill="url(#colorUv)" />
                    <Line type="monotone" name="BZM Log" dataKey="FileLine" stroke="none" fillOpacity={1} fill="url(#colorUv)" style={{ overflowWrap: 'break-word' }} />
                    <Line type="monotone" name="Agent Count" dataKey="CountBPI" stroke="green" fillOpacity={1} fill="url(#colorUv)" />
                    <Line type="monotone" name="Agent Error Code" dataKey="ErrorGroup_Agent" stroke="none" fillOpacity={1} fill="url(#colorUv)" />
                    <Line type="monotone" name="Agent Log" dataKey="Fileline_Agent" stroke="none" fillOpacity={1} fill="url(#colorUv)" />
                  </LineChart>
                </div>
              }
            </div>
          )
          : <>
            {selectGraph === "graph" ?
              <div>
                {loader ? (
                  <>
                    <div
                      style={style}
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <Loader
                        themeColor={"dark"}
                        size="medium"
                        type="converging-spinner"
                      />
                      &nbsp;&nbsp;
                      <div>Please Wait...</div>
                    </div>
                  </>
                ) : (
                  <div>
                    {initialData.length === 0 ?
                      <div class="empty-state">
                        <div class="empty-state__content">
                          <div class="empty-state__icon">
                            <img src={NoData} alt="No Data Found" width={200} height={200} />
                          </div>
                          <div class="empty-state__message">Yay, your test doesn't have any errors !</div>
                        </div>
                      </div>
                      :
                      <ExcelExport ref={_export}>
                        <Grid
                          style={style}
                          data={newData}
                          groupable={restDatatableData.groupable}
                          onGroupChange={onGroupChange}
                          group={group}
                          onExpandChange={onExpandChange}
                          expandField="expanded"
                          sort={sort}
                          sortable={true}
                          onSortChange={onSortChange}
                          filterable={restDatatableData.filterable}
                          filter={filter}
                          onFilterChange={onFilterChange}
                          resizable={true}
                          skip={page.skip}
                          take={page.take}
                          total={dataCount}
                          pageable={restDatatableData.pageable}
                          onPageChange={pageChange}
                        >
                          <GridToolbar>
                            <div className="d-flex justify-content-end w-100">
                              <button
                                title="Export Excel"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btn btn-primary"
                                onClick={excelExport}
                                disabled={csvButtonStatus}
                              >
                                Export to Excel
                              </button>
                            </div>
                          </GridToolbar>
                          <GridNoRecords>
                            Yay, your test doesn't have any errors !
                          </GridNoRecords>
                          {headerData.map((value, index) => {
                            return (
                              <GridColumn
                                key={index}
                                field={value.field}
                                title={value.title}
                                minResizableWidth={value.minResizableWidth}
                                width={value.width}
                                headerCell={CustomHeader}
                                cell={CustomCell}
                              />
                            );
                          })}
                        </Grid>
                      </ExcelExport>
                    }
                  </div>
                )}
              </div> :
              <div>
                {
                  initialData.length != 0 ?
                    <div>
                      {
                        selectGraph === "correlationTable" &&
                        // <div style={{ overflowY: 'auto' }}>
                        //   <table class="table table-bordered">
                        //     <thead style={{ backgroundColor: "#f8f9fa" }}>
                        //       <tr>
                        //         <th scope="col" class="text-center">Sr No</th>
                        //         <th scope="col" class="text-center">Time Period</th>
                        //         <th scope="col" class="text-center">BZM Count</th>
                        //         <th scope="col" class="text-center">BZM Error Code</th>
                        //         <th scope="col" class="text-center">BZM Log</th>
                        //         <th scope="col" class="text-center">Agent Count</th>
                        //         <th scope="col" class="text-center">Agent Error Code</th>
                        //         <th scope="col" class="text-center">Agent Log</th>
                        //       </tr>
                        //     </thead>
                        //     <tbody>
                        //       {
                        //         blazemeterAggregateData?.map((log, index) => {
                        //           return (
                        //             <tr>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{index}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log._id === undefined ? "-" : moment(log._id).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.Count === undefined ? "-" : log.Count}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.ErrorGroup === undefined ? "-" : log.ErrorGroup}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.FileLine === undefined ? "-" : log.FileLine}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.CountBPI === undefined ? "-" : log.CountBPI}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.ErrorGroup_Agent === undefined ? "-" : log.ErrorGroup_Agent}</td>
                        //               <td style={{ whiteSpace: 'wrap', wordBreak: 'break-all', textAlign: 'left' }}>{log.Fileline_Agent === undefined ? "-" : log.Fileline_Agent}</td>
                        //             </tr>
                        //           )
                        //         })
                        //       }
                        //     </tbody>
                        //   </table>
                        // </div>

                        <Grid
                          data={blazemeterAggregateData.map((value) => { return { ...value, _id: moment(value._id).format('MMMM Do YYYY, h:mm:ss a') } }).slice(page.skip, page.take + page.skip)}
                          skip={page.skip}
                          take={page.take}
                          resizable={true}
                          total={blazemeterAggregateData.length}
                          pageable={true}
                          onPageChange={correlationPageChange}
                        >
                          <GridNoRecords>
                            Yay, your test doesn't have any errors !
                          </GridNoRecords>
                          {correlationTableHeader.map((value, index) => {
                            return (
                              <GridColumn
                                key={index}
                                field={value.field}
                                title={value.title}
                                minResizableWidth={value.minResizableWidth}
                                width={value.width}
                                headerCell={CustomCorrelationHeader}
                                cell={CustomCorrelationCell}
                              />
                            );
                          })}
                        </Grid>
                      }
                      
                      </div> :
                    <div class="empty-state">
                      <div class="empty-state__content">
                        <div class="empty-state__icon">
                          <img src={NoData} alt="No Data Found" width={200} height={200} />
                        </div>
                        <div class="empty-state__message">Yay, your test doesn't have any errors !</div>
                      </div>
                    </div>
                }
              </div>
            }
          </>)
      }
      
    </>
  );
};