import React, { Fragment, useEffect, useState } from "react";
import "../style/LogMonitor.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    addLogEntryApi,
    getLogEntries,
    getLogSettings,
} from "../apis/LogMonitor";
import Popup from "./Popup";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export const AddLogFile = () => {
    const navigate = useNavigate();
    const { agentId } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [logEntriesData, setLogEntriesData] = useState();
    const [getLogSetting, setGetLogSetting] = useState();
    const [loader, setLoader] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation();

    console.log("Location ====> ", location);
    // view logs for particular file
    const viewLogs = (value) => {
        if (!isOpen && value > 0) {
            setLoader(true);
            setIsOpen(!isOpen);
            getLogEntries(agentId, value).then((response) => {
                setLogEntriesData(response.data);
                setLoader(false);
            });
        } else {
            setIsOpen(!isOpen);
        }
    };

    // get all log after component loaded
    useEffect(() => {
        getAllLogSettings();
    }, []);

    // display all log settings
    const getAllLogSettings = () => {
        getLogSettings(agentId).then((response) => {
            setGetLogSetting(response.data);
        });
    };

    const backToHomePage = () => {
        navigate("/log_monitor", {
            state: {
                checkTable: "callTable",
            },
        });
    };

    // update changes code
    const addMonitorfile = () => {
        const updatedConfigurationList = { ...getLogSetting };
        updatedConfigurationList.ListLogFileConfiguration.push({
            AgentLogFileConfigId: 0,
            FriendlyName: "",
            FileName: "",
            AlertsEmailRecipient: null,
            KeywordList: [],
            DateFormat: "yyyy-MM-dd HH:mm:ss"
        });
        setGetLogSetting(updatedConfigurationList);
    };

    // save data
    const saveEntryData = (e) => {
        e.preventDefault();
        setSaveLoader(true);
        addLogEntryApi(getLogSetting).then((response) => {
            getAllLogSettings();
            setSaveLoader(false);
        });
    };

    const deleteEntryData = () => {
        setDeleteLoader(true)
        var updatedConfigurationList = { ...getLogSetting };
        var oldKeywordArray;
        if (selectLogFile.length > 0) {
            selectLogFile.map((key) => {
                oldKeywordArray =
                    updatedConfigurationList.ListLogFileConfiguration.filter(
                        (log) => log.AgentLogFileConfigId !== key
                    );
                updatedConfigurationList = {
                    AgentId: updatedConfigurationList.AgentId,
                    ListLogFileConfiguration: oldKeywordArray,
                };
            });
        }
        addLogEntryApi(updatedConfigurationList).then((response) => {
            getAllLogSettings();
            setDeleteLoader(false)
            setSelectLogFile([])
            handleClose();
        });
    };

    const [selectLogFile, setSelectLogFile] = useState([]);
    const selectLogFiles = (e, agentFileId) => {
        if (e.target.checked) {
            if (!selectLogFile.includes(agentFileId)) {
                setSelectLogFile([...selectLogFile, agentFileId]);
            }
        } else {
            if (selectLogFile.includes(agentFileId)) {
                setSelectLogFile(
                    selectLogFile.filter((indexVal) => indexVal !== agentFileId)
                );
            }
        }
    };

    const deleteKeyword = (index, keyIndex) => {
        const updatedConfigurationList = { ...getLogSetting };
        const oldKeywordArray =
            updatedConfigurationList.ListLogFileConfiguration[index].KeywordList;
        const updatedKeywordList = oldKeywordArray.filter(
            (item, iIndex) => iIndex != keyIndex
        );
        updatedConfigurationList.ListLogFileConfiguration[index].KeywordList =
            updatedKeywordList;
        setGetLogSetting(updatedConfigurationList);
    };


    const addKeyword = (index) => {
        const updatedConfigurationList = { ...getLogSetting };
        updatedConfigurationList.ListLogFileConfiguration[index].KeywordList.push({
            Keyword: "",
            AlertSeverity: 1,
        });
        setGetLogSetting(updatedConfigurationList);
    };

    const addFriendlyName = (index, name) => {
        const getlogs = { ...getLogSetting };
        getlogs.ListLogFileConfiguration[index].FriendlyName = name;
        setGetLogSetting(getlogs);
    };

    const addFileName = (index, fileName) => {
        const getlogs = { ...getLogSetting };
        getlogs.ListLogFileConfiguration[index].FileName = fileName;
        setGetLogSetting(getlogs);
    };

    const addKeywordValue = (index, keyIndex, keywordValue) => {
        const getlogs = { ...getLogSetting };
        getlogs.ListLogFileConfiguration[index].KeywordList[keyIndex].Keyword =
            keywordValue;
        setGetLogSetting(getlogs);
    };
    const addDateFormat = (index, format) => {
        const getLogs = { ...getLogSetting };
        console.log(getLogs);
        getLogs.ListLogFileConfiguration[index].DateFormat = format;
        setGetLogSetting(getLogs);
    }


    const popover = (
        <Popover id="popover-basic" style={{ maxWidth: "800px" }}>
            <Popover.Header as="h3"> Default Keywords</Popover.Header>
            <Popover.Body>
                <strong>Once you configure a monitor for a specific log file, the following keywords are monitored by default.</strong>
                <li>400 - 600 Error code</li>
                <li>Non HTTP response code: javax.net.ssl.SSLException</li>
                <li>Exception</li>
                <li>Error</li>
            </Popover.Body>
        </Popover>
    );
    const agentinfo = (
        <Popover id="popover-basic" style={{ maxWidth: "800px" }}>
            <Popover.Header as="h3">File Path</Popover.Header>
            <Popover.Body >
                <strong>Example:-</strong>
                <ul>
                    <li>Window :- C:\FolderPath\Filename.log</li>
                    <li>Linux :- /home/FolderPath/Filename.log</li>
                </ul>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <form onSubmit={(e) => saveEntryData(e)}>
                <div className="container mt-2">
                    <div style={{ textAlign: "right" }}>
                        <button
                            type="button"
                            class="btn btn-secondary mb-1"
                            onClick={backToHomePage}
                        >
                            Agent List
                        </button>
                    </div>
                    <div>
                        <div class="masterTableTitle text-dark fw-bolder fs-5">
                            {location?.state} Log Configuration <sub>
                                {" "}
                                <OverlayTrigger
                                    trigger="hover"
                                    placement="right"
                                    overlay={popover}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </OverlayTrigger>
                            </sub>
                        </div>
                        <ul class="masterTableHeader">
                            <li class="w-9">Select</li>
                            <li class="w-8">Monitor Name</li>
                            <li class="w-25">File Location</li>
                            <li class="w-25">Keywords</li>
                        </ul>
                    </div>
                    <div className="masterTableBodyDivisionAgent">
                        <div>
                            <Fragment>
                                {showLoader ? (
                                    <div
                                        className="w-40  d-flex justify-content-center align-items-center"
                                        style={{ marginTop: "50px" }}
                                    >
                                        {" "}
                                        <Loader
                                            themeColor={"dark"}
                                            size="medium"
                                            type="converging-spinner"
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        {getLogSetting?.ListLogFileConfiguration.length !== 0 &&
                                            getLogSetting?.ListLogFileConfiguration.map(
                                                (logs, index) => {
                                                    return (
                                                        <div className="row p-1">
                                                            <div className="col-md-1 my-2 text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectLogFile.includes(logs.AgentLogFileConfigId) ? true : false}
                                                                    onChange={(e) =>
                                                                        selectLogFiles(
                                                                            e,
                                                                            logs.AgentLogFileConfigId
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-md-3 my-2 text-center">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Monitor Name"
                                                                    required
                                                                    value={logs.FriendlyName}
                                                                    onChange={(e) =>
                                                                        addFriendlyName(index, e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-md-4 my-2 text-center">
                                                                <div className="row">
                                                                    <div className="col-11">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="File location"
                                                                            required
                                                                            value={logs.FileName}
                                                                            onChange={(e) =>
                                                                                addFileName(index, e.target.value)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-1 mt-2" >
                                                                        <OverlayTrigger
                                                                            trigger="hover"
                                                                            placement="right"
                                                                            overlay={agentinfo}
                                                                        >
                                                                            <i className="bi bi-info-circle"></i>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 my-2">
                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="yyyy-MM-dd HH:mm:ss"
                                                                            required
                                                                            value={logs.DateFormat}
                                                                            onChange={(e) =>
                                                                                addDateFormat(index, e.target.value)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <button
                                                                            class="btn btn-secondary"
                                                                            onClick={() => addKeyword(index)}
                                                                        >
                                                                            Add Keyword
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            style={{ marginLeft: "5px" }}
                                                                            disabled={logs.AgentLogFileConfigId === 0}
                                                                            onClick={() =>
                                                                                viewLogs(logs.AgentLogFileConfigId)
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            View
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                </div>
                                                                <div className="text-center">
                                                                    {logs.KeywordList.map((key, keyIndex) => (
                                                                        !key.IsDefault &&
                                                                        <div class="row mt-3" id={keyIndex}>
                                                                            <div class="col-6 mt-1">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Keyword"
                                                                                    required
                                                                                    name="Keyword"
                                                                                    value={key.Keyword}
                                                                                    onChange={(e) =>
                                                                                        addKeywordValue(
                                                                                            index,
                                                                                            keyIndex,
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger m-1"
                                                                                    onClick={() =>
                                                                                        deleteKeyword(index, keyIndex)
                                                                                    }
                                                                                >
                                                                                    Delete
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                )}
                            </Fragment>
                        </div>
                        <div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-success m-2"
                                    style={{ background: "#01447d" }}
                                    onClick={() => addMonitorfile()}
                                >
                                    Add Monitor
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-success m-1"
                                    style={{ background: "#01447d" }}
                                >
                                    {saveLoader ? (
                                        <div>
                                            <Loader
                                                themeColor={"white"}
                                                size="small"
                                                type="converging-spinner"
                                            />
                                        </div>
                                    ) : (
                                        <div>Save</div>
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger m-1"
                                    disabled={selectLogFile.length === 0}
                                    onClick={handleShow}
                                >
                                    Delete
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="container">
                {isOpen && (
                    <Popup
                        content={
                            <>
                                {
                                    loader ? <div className="d-flex justify-content-center">
                                        <Loader
                                            themeColor={"dark"}
                                            size="medium"
                                            type="converging-spinner"
                                        />
                                    </div> :
                                        <div class="container">
                                            <div>
                                                <div>
                                                    <div class="masterTableTitle text-dark fw-bolder fs-6">
                                                        {logEntriesData && logEntriesData.FileName == null ? (
                                                            <div class="text-danger">Keywords Not Found</div>
                                                        ) : (
                                                            <div>File Name: {logEntriesData.FileName}</div>
                                                        )}
                                                    </div>
                                                    <ul class="masterTableHeader">
                                                        <li class="w-10">Sr. No</li>
                                                        <li class="w-20">Keyword</li>
                                                        <li class="w-20">FileLine</li>
                                                        <li class="w-20">LoggedAt</li>
                                                        <li class="w-20">AlertSeverity</li>
                                                    </ul>
                                                </div>
                                                <div className="masterTableBodyDivision">
                                                    {logEntriesData &&
                                                        logEntriesData.LogDataList.slice(0,10).map((log, index) => (
                                                            <ul
                                                                key={"body" + index}
                                                                className="masterTableBody border-bottom mb-0 py-3"
                                                            >
                                                                <li className="w-10 d-flex justify-content-center align-items-center">
                                                                    <div>{index + 1}</div>
                                                                </li>
                                                                <li
                                                                    className="w-20 d-flex justify-content-center align-items-center"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <div style={{ wordBreak: "break-word" }}>
                                                                        {log.Keyword}
                                                                    </div>
                                                                </li>
                                                                <li className="w-20 d-flex justify-content-center align-items-center">
                                                                    <div
                                                                        className="text-wrap"
                                                                        style={{ inlineSize: "200px" }}
                                                                    >
                                                                        {log.FileLine}
                                                                    </div>
                                                                </li>
                                                                <li className="w-20 d-flex justify-content-center align-items-center">
                                                                    <div className="text-wrap">{log.LoggedAt}</div>
                                                                </li>
                                                                <li className="w-20 d-flex justify-content-center align-items-center">
                                                                    <div className="text-wrap">
                                                                        {log.AlertSeverity}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>

                                }
                            </>
                        }
                        handleClose={viewLogs}
                    />
                )}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deleteEntryData}>
                        {deleteLoader ? (
                            <div>
                                <Loader
                                    themeColor={"white"}
                                    size="small"
                                    type="converging-spinner"
                                />
                            </div>
                        ) : (
                            <div>OK</div>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};