import axios from "axios";
import { toast } from "react-toastify";

export const getAllAgentInfo = async (emailId) => {
    try {
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_all_agent_info`,
            {
                emailId: emailId,
            }
        )
    } catch (error) 
    {
        toast.error(error.response.data);
    }
};

export const downloadWindowAgent = async (emailId) =>
{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/download_window_agent`,
            {
                emailId: emailId,
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const getLogMonitorUserInfo = async (emailId) =>
{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_log_monitor_user_info`,
            {
                emailId: emailId,
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const addLogEntryApi = async (logEntry) =>
{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/add_log_entry`,
            {
                logEntry: logEntry,
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const getLogEntries = async(AgentId, LogConfigId) =>
{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_log_entries`,
            {
                AgentId: AgentId,
                LogConfigId: LogConfigId
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const getLogSettings = async (AgentId) =>{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_log_settings`,
            {
                AgentId: AgentId,
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const getCoreleationLogData = async (StartTime, EndTime, AgenIds, Keyword) =>{
    try
    {
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_test_mapped_logs`,
            {
                StartTime: StartTime,
                EndTime: EndTime,
                AgenIds: AgenIds,
                Keywords: Keyword
            }
        )
    }
    catch(error)
    {
        toast.error(error.response.data);
    }
}

export const getBlazemeterAggregateData = async (masterId) =>{
    try{
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT}/get_blazemeter_aggregate_data`,
            {
                masterId: masterId,
            }
        )
    }
    catch(error){
        toast.error(error.response.data);
    }
}