import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import { Fragment } from "react";
import "../style/CustomTable.css";
export const CustomTable = (props) => {
  const { header, body, getSessionIdList, showWorkSpace = false } = props;

  let statusObject = {
    noData: "No Data",
    pass: "Pass",
    fail: "Fail",
    unset: "Not Set",
    error: "Error",
    abort: "Abort",
  };
  let width10HeaderData = ["Location", "Sr No"];
  const getName = (name, id) => {
    document.getElementById(id).innerText = name;
  }
  const getNameSlice = (name, id) => {
    document.getElementById(id).innerText = name.slice(0, 10) + (name.length > 10 ? '...' : "");

  }

  return (
    <>
      <div className="masterTableHeaderDivision p-0">
        <div className="masterTableTitle text-dark fw-bolder fs-5 py-1">
          Recent Test Runs
        </div>
        <ul className="masterTableHeader p-0">
          {header.map((headerValue, index) => {
            return (
              <li key={"header" + index} className={width10HeaderData.includes(headerValue) ? "w-10" : "w-20"}>
                {headerValue}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="masterTableBodyDivision p-0">
        <Fragment>
          {showWorkSpace ?
            <div className="w-40  d-flex justify-content-center align-items-center"
              style={{ marginTop: "100px" }} ><Loader
                themeColor={"dark"}
                size="medium"
                type="converging-spinner"
              /></div>
            :
            <Fragment>
              {body.map((headerBody, index) => {
                return (
                  <ul
                    key={"body" + index}
                    className="masterTableBody border-bottom mb-0 py-1"
                  >
                    <li
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => getSessionIdList(headerBody.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="text-primary" id={headerBody.id} style={{ wordBreak: "break-word" }} onMouseOver={() => getName(headerBody.name, headerBody.id)} onMouseOut={() => getNameSlice(headerBody.name, headerBody.id)}>{headerBody.name.slice(0, 10) + (headerBody.name.length > 10 ? '...' : "")}</div>
                    </li>
                    <li className="w-20 d-flex justify-content-center align-items-center">
                      <div>{headerBody.id}</div>
                    </li>
                    <li className="w-20 d-flex justify-content-center align-items-center">
                      <div className="fst-italic fs-10">
                        {moment().diff(
                          moment(Number(headerBody.created) * 1000),
                          "minutes"
                        ) < 60
                          ? `${moment().diff(
                            moment(Number(headerBody.created) * 1000),
                            "minutes"
                          )} minutes ago`
                          : moment().diff(
                            moment(Number(headerBody.created) * 1000),
                            "hours"
                          ) < 24
                            ? `${moment().diff(
                              moment(Number(headerBody.created) * 1000),
                              "hours"
                            )} hours ago`
                            : moment().diff(
                              moment(Number(headerBody.created) * 1000),
                              "days"
                            ) < 31
                              ? `${moment().diff(
                                moment(Number(headerBody.created) * 1000),
                                "days"
                              )} days ago`
                              : moment().diff(
                                moment(Number(headerBody.created) * 1000),
                                "month"
                              ) < 12
                                ? `${moment().diff(
                                  moment(Number(headerBody.created) * 1000),
                                  "month"
                                )} month ago`
                                : `${moment().diff(
                                  moment(Number(headerBody.created) * 1000),
                                  "year"
                                )} Year ago`}
                      </div>
                    </li>
                    <li className="w-20 text-center d-flex justify-content-center align-items-center">
                      <div
                        className={`${Object.keys(statusObject).find(
                          (key) => key === headerBody.reportStatus
                        )} statusCommonCss`}
                      >
                        {statusObject[headerBody.reportStatus]}
                      </div>
                    </li>
                  </ul>
                );
              })}

            </Fragment>
          }
        </Fragment>
      </div>
    </>
  );
};