import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthorizedRoutes } from "./AuthorizedRoutes";
import { GlobalRoutes } from "./GlobalRoutes";
import { ErrorAnalysis } from "../pages/ErrorAnalysis";
import { HomePage } from "../pages/HomePage";
import { Setting } from "../pages/Setting";
import { APMMatrix } from "../pages/ApmMatrix";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { WrapperComponent } from "./WrapperComponent";
import { AgentDownload } from "../pages/AgentDownload";
import { AddLogFile } from "../pages/AddLogFile";
import { NewUi } from "../pages/NewUi";
import { Header1 } from "../pages/Header1";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <GlobalRoutes>
               {/* <Header1 />  */}
              <HomePage />
              {/* <Footer /> */}
            </GlobalRoutes>
          }
        />
        <Route
          path="performance_insights1"
          element={
            <AuthorizedRoutes>
              <WrapperComponent breadCrumbData="Performance Insights" path="performance_insights">
                <div className="mainComponent">
                  <ErrorAnalysis />
                </div>
              </WrapperComponent>
            </AuthorizedRoutes>
          }
        >
          <Route
            path=":id"
            element={
              <AuthorizedRoutes>
                <WrapperComponent breadCrumbData="Performance Insights" path=":id">
                  <div className="mainComponent">
                    <ErrorAnalysis />
                  </div>
                </WrapperComponent>
              </AuthorizedRoutes>
            }
          >
          </Route>
        </Route>
        <Route
          path="setting"
          element={
            <AuthorizedRoutes>
              <WrapperComponent breadCrumbData="Settings" path="setting">
                <div className="mainComponent">
                  <Setting />
                </div>
              </WrapperComponent>
            </AuthorizedRoutes>
          }
        />
        <Route
          path="apm_connector"
          element={
            <AuthorizedRoutes>
              <WrapperComponent breadCrumbData="Perfmon Connector" path="apm_connector">
                <div className="mainComponent">
                  <APMMatrix />
                </div>
              </WrapperComponent>
            </AuthorizedRoutes>
          }
        />
        <Route
          path="log_monitor"
          element={
            <AuthorizedRoutes>
              <WrapperComponent breadCrumbData="Log Monitor Agent" path="log_monitor">
                <div className="mainComponent">
                  <AgentDownload />
                </div>
              </WrapperComponent>
            </AuthorizedRoutes>
          }
        />
        <Route
          path="/log_monitor/log/:agentId"
          element={
            <AuthorizedRoutes>
              <WrapperComponent breadCrumbData="Log Monitor Agent" path="/log_monitor">
                <div className="mainComponent">
                  <AddLogFile />
                </div>
              </WrapperComponent>
            </AuthorizedRoutes>
          }
        />
         <Route
          path="performance_insights"
          element={
            <AuthorizedRoutes>
              <WrapperComponent>
                  <NewUi/>
                  </WrapperComponent>
            </AuthorizedRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};