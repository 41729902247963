import axios from "axios";
import { toast } from "react-toastify";
import FileDownload from "js-file-download";

export const getScenarioId = async ({ key, secret, masterId }) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_PORT}/get_scenario_ids`,
      {
        apikey: key,
        apisecret: secret,
        masterId: masterId,
      }
    );
  } catch (error) {
    toast.error(error.response.data);
  }
}

export const getSessionId = async ({ key, secret, masterId, scenarioId, multiTestStatus }) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_PORT}/get_session_ids`,
      {
        apikey: key,
        apisecret: secret,
        masterId: masterId,
        scenarioIdList: scenarioId,
        multiTestStatus: multiTestStatus
      }
    );
  } catch (error) {
    toast.error(error.response.data);
  }
};

export const getSessionIdData = async (
  formData,
  id,
  masterId,
  sessionIdArray,
  skip,
  take,
  filterQuery
) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_PORT}/get_session_errors${filterQuery ? filterQuery : ""
      }`,
      {
        apikey: formData.key,
        apisecret: formData.secret,
        sessionId: id,
        masterId: masterId,
        sessionIdArray: sessionIdArray,
        skip: skip,
        take: take,
      }
    );
  } catch (error) {
    toast.error(error?.response?.data);
  }
};

export const getErrorDatatableAllData = async (
  { key, secret },
  masterId, selectedSessionId
) => {
  await axios
    .post(`${process.env.REACT_APP_BACKEND_PORT}/get_session_error_all_data`, {
      apiKey: key,
      apiSecret: secret,
      masterId: masterId,
      selectedSessionIdArray: selectedSessionId,
    })
    .then((response) => {
      if (Object.keys(response).length) {
        FileDownload(response?.data, "Error Data Report.csv");
        return { status: true };
      }
      toast.warn("No data found");
    })
    .catch((error) => {
      toast.warn(error.response.data.message);
      return { status: true };
    });
};

export const getTimeStampDetails = async (apiSecretData) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_PORT}/get_start_and_end_time`,
    {
      apiKey: apiSecretData.apiKey,
      apiSecret: apiSecretData.apiSecret,
      masterId: apiSecretData.masterId,
    }
  );
};

export const getWorkSpaceIdList = async ({ key, secret }, accountId) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_PORT}/get_work_space_id_list`,
    {
      apiKey: key,
      apiSecret: secret,
      accountId: accountId,
    }
  );
};
export const getMasterIdList = async ({ key, secret }, workSpaceId) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_PORT}/get_master_id_list`,
    {
      apiKey: key,
      apiSecret: secret,
      workSpaceId: workSpaceId,
    }
  );
};