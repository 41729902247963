import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import { getUserDetails } from "../apis/authDetails";

export const Setting = () => {
  const [apiAndSecretFormData, setApiAndSecretFormData] = useState({
    key: "",
    secret: "",
    keyStatus: true,
    secretStatus: true,
    submitButtonStatus: false,
  });

  useEffect(() => {
    let { key, secret } = JSON.parse(localStorage.getItem("apiSecretData"));
    let decryptedKey = CryptoJS.AES.decrypt(
      key,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    let decryptedSecret = CryptoJS.AES.decrypt(
      secret,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    let showKey =
      Array(decryptedKey.length).join("*") +
      decryptedKey.substr(decryptedKey.length - 4, 4);
    let showSecret =
      Array(decryptedSecret.length).join("*") +
      decryptedSecret.substr(decryptedSecret.length - 4, 4);
    setApiAndSecretFormData((preVal) => ({
      ...preVal,
      key: `${showKey}`,
      secret: `${showSecret}`,
    }));
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    var cipherKey = CryptoJS.AES.encrypt(
      apiAndSecretFormData.key,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();
    var cipherSecret = CryptoJS.AES.encrypt(
      apiAndSecretFormData.secret,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();
    let data = {
      key: cipherKey,
      secret: cipherSecret,
    };
    getUserDetails(data)
      .then((response) => {
        localStorage.setItem("apiSecretData", JSON.stringify(data));
        let showKey =
          Array(apiAndSecretFormData.key.length).join("*") +
          apiAndSecretFormData.key.substr(
            apiAndSecretFormData.key.length - 4,
            4
          );
        let showSecret =
          Array(apiAndSecretFormData.secret.length).join("*") +
          apiAndSecretFormData.secret.substr(
            apiAndSecretFormData.secret.length - 4,
            4
          );
        toast.success("API Keys updated Successfully");

        setApiAndSecretFormData({
          key: showKey,
          secret: showSecret,
          keyStatus: true,
          secretStatus: true,
          submitButtonStatus: false,
        });
      })
      .catch((error) => {
        toast.error("Failed to update ! Please check your credentials");
      });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setApiAndSecretFormData({
      ...apiAndSecretFormData,
      [name]: value,
    });
  };
  const handleEdit = () => {
    let { key, secret } = JSON.parse(localStorage.getItem("apiSecretData"));
    let decryptedKey = CryptoJS.AES.decrypt(
      key,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    let decryptedSecret = CryptoJS.AES.decrypt(
      secret,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    setApiAndSecretFormData((preVal) => ({
      ...preVal,
      key: decryptedKey,
      secret: decryptedSecret,
      keyStatus: false,
      secretStatus: false,
      submitButtonStatus: true,
    }));
  };
  const handleCancel = () => {
    let { key, secret } = JSON.parse(localStorage.getItem("apiSecretData"));
    let decryptedKey = CryptoJS.AES.decrypt(
      key,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    let decryptedSecret = CryptoJS.AES.decrypt(
      secret,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    let showKey =
      Array(decryptedKey.length).join("*") +
      decryptedKey.substr(decryptedKey.length - 4, 4);
    let showSecret =
      Array(decryptedSecret.length).join("*") +
      decryptedSecret.substr(decryptedSecret.length - 4, 4);
    setApiAndSecretFormData((preVal) => ({
      ...preVal,
      key: `*****${showKey}`,
      secret: `*****${showSecret}`,
      keyStatus: true,
      secretStatus: true,
      submitButtonStatus: false,
    }));
  };
  return (
    <>
      <ToastContainer />
      <Row className="mx-5 alert alert-primary">
        <Form noValidate className="mt-5">
          <div className="d-flex mb-3 justify-content-center">
            <Form.Label
              className="col-1 d-flex align-items-center text-dark"
              style={{ fontWeight: "500" }}
            >
              API Key :
            </Form.Label>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Control
                name="key"
                required
                type="text"
                placeholder="Enter API Key"
                value={apiAndSecretFormData.key}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
                className="form-control col-10"
                disabled={apiAndSecretFormData.keyStatus}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-center">
            <Form.Label
              className="col-1 d-flex align-items-center text-dark"
              style={{ fontWeight: "500" }}
            >
              Secret Key :
            </Form.Label>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Control
                name="secret"
                required
                type="text"
                placeholder="Enter Secret Key"
                value={apiAndSecretFormData.secret}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
                className="form-control col-10"
                disabled={apiAndSecretFormData.secretStatus}
              />
            </Form.Group>
          </div>
        </Form>
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-primary mt-4 mb-3 fontStyle mx-2 col-1"
            onClick={
              apiAndSecretFormData.submitButtonStatus
                ? handleSubmit
                : handleEdit
            }
            disabled={
              !apiAndSecretFormData.key || !apiAndSecretFormData.secret
                ? true
                : false
            }
          >
            {apiAndSecretFormData.submitButtonStatus ? "Update" : "Edit"}
          </button>
          {apiAndSecretFormData.submitButtonStatus ? (
            <button
              type="button"
              className="btn btn-primary mt-4 mb-3 fontStyle mx-2 col-1"
              onClick={handleCancel}
            >
              Cancel
            </button>
          ) : null}
        </div>
      </Row>
    </>
  );
};
