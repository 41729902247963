import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal, Spinner } from "react-bootstrap";
import {
  submitApmMatrixFiles,
  submitApmSelectedMatrixData,
} from "../apis/APMMatrix";
import { ApiAndSecretForm } from "../components/creds_info/ApiAndSecretForm";
import { Loader } from "@progress/kendo-react-indicators";
import "../style/ApmMatrixStyle.css";
import { ToastContainer } from "react-toastify";
import { MatrixTableComponent } from "../components/apm_matrix/TableComponent";

export const APMMatrix = () => {
  let [showModal, setShowModal] = useState(false);
  let [showConfirm, setShowConfirm] = useState(false);
  let [disabled, setDisabled] = useState(false);
  let [link, setLink] = useState("");
  let [apmMatrixData, setAPMMatrixData] = useState({
    index: null,
    data: [],
    objectOfId: {},
    submitButtonStatus: true,
    selectedFile: [],
    selectAllCheck: {},
  });

  let [showApiSecretForm, setShowApiSecretForm] = useState(true);
  let [apmMatrixFormData, setApmMatrixFormData] = useState({
    masterId: "",
    labelName: "",
    filesArray: [],
    submitButtonStatus: true,
  });
  let [loader, setloader] = useState(false);
  let [spinner, setSpinner] = useState(false);
  let [clearApmMatrixData, setClearApmMatrixData] = useState(false);

  useEffect(() => {
    let apiSecretData = localStorage.getItem("apiSecretData");
    let objectOfIdLength = Object.keys(apmMatrixData.objectOfId).length;
    if (apmMatrixData.data.length === objectOfIdLength) {
      setAPMMatrixData({
        ...apmMatrixData,
        submitButtonStatus: false,
      });
    } else {
      setAPMMatrixData({
        ...apmMatrixData,
        submitButtonStatus: true,
      });
    }

    if (apiSecretData) {
      setShowApiSecretForm(false);
    }
    if (apmMatrixFormData.filesArray.length !== 0) {
      setApmMatrixFormData((preVal) => ({
        ...preVal,
        submitButtonStatus: false,
      }));
    } else {
      setApmMatrixFormData((preVal) => ({
        ...preVal,
        submitButtonStatus: true,
      }));
    }
    //eslint-disable-next-line
  }, [
    apmMatrixFormData.masterId,
    apmMatrixFormData.filesArray,
    apmMatrixData.objectOfId,
  ]);

  const handleChange = (e, type) => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "masterId" || type === "labelName") {
      setApmMatrixFormData({
        ...apmMatrixFormData,
        [name]: value,
      });
    } else {
      let filesArray = Array.from(e.target.files);
      setApmMatrixFormData({
        ...apmMatrixFormData,
        filesArray: filesArray,
      });
    }
  };
  const handleSubmit = (e) => {
    setloader(true);
    e.preventDefault();
    let formData = new FormData();
    let filesNamesArray = apmMatrixData.selectedFile.map((value) => {
      return value.name;
    });
    let filteredFiles = apmMatrixFormData.filesArray.filter((file) => {
      return !filesNamesArray.includes(file.name);
    });
    filteredFiles.map((file) => {
      return formData.append("perfMonFile", file);
    });
    if (filteredFiles.length) {
      submitApmMatrixFiles(formData).then((response) => {
        setAPMMatrixData({
          ...apmMatrixData,
          index: 0,
          data: [...apmMatrixData.data, ...response.data],
          submitButtonStatus: true,
          selectedFile: [...apmMatrixData.selectedFile, ...filteredFiles],
        });
      });
    }
    document.getElementById("apiMatrixForm").reset();
    setApmMatrixFormData({
      masterId: "",
      filesArray: [],
      submitButtonStatus: true,
    });
    setloader(false);
    setClearApmMatrixData(true);
  };

  const handleApmMatrixData = (value) => {
    let index;
    if (value === "prev") {
      index = apmMatrixData.index - 1;
    } else {
      index = apmMatrixData.index + 1;
    }

    setAPMMatrixData({
      ...apmMatrixData,
      index: index,
    });
  };

  const selectCheckboxData = (key, itemIndex) => {
    let initiatedkeys = Object.keys(apmMatrixData.objectOfId);
    if (initiatedkeys.includes(key)) {
      let oldObjectOfId = apmMatrixData.objectOfId;
      let targetObject = oldObjectOfId[key];
      if (targetObject.includes(itemIndex)) {
        let indexOfItem = targetObject.indexOf(itemIndex);
        targetObject.splice(indexOfItem, 1);
        if (targetObject.length === 0) {
          delete oldObjectOfId[key];
        }
      } else {
        targetObject.push(itemIndex);
      }
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...oldObjectOfId },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [key]:
            oldObjectOfId[key] &&
            oldObjectOfId[key].length ===
              Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
              ? true
              : false,
        },
      });
    } else {
      let oldObjectOfId = apmMatrixData.objectOfId;
      let object = {
        [key]: [itemIndex],
      };
      oldObjectOfId = { ...oldObjectOfId, ...object };
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...oldObjectOfId },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [key]:
            oldObjectOfId[key] &&
            oldObjectOfId[key].length ===
              Object.keys(apmMatrixData.data[apmMatrixData.index][key]).length
              ? true
              : false,
        },
      });
    }
  };

  const selectAll = (filename) => {
    if (
      apmMatrixData.objectOfId[filename] &&
      apmMatrixData.objectOfId[filename].length ===
        Object.keys(apmMatrixData.data[apmMatrixData.index][filename]).length
    ) {
      let tempData = { ...apmMatrixData.objectOfId };
      delete tempData[filename];
      setAPMMatrixData({
        ...apmMatrixData,
        objectOfId: { ...tempData },
        selectAllCheck: {
          ...apmMatrixData.selectAllCheck,
          [filename]: false,
        },
      });
    } else {
      let tempData = { ...apmMatrixData };
      tempData.objectOfId[filename] = Object.keys(
        Object.values(
          apmMatrixData.data[
            apmMatrixData.data.findIndex(
              (obj) => Object.keys(obj)[0] === filename
            )
          ]
        )[0]
      );
      tempData.selectAllCheck = {
        ...apmMatrixData.selectAllCheck,
        [filename]: true,
      };
      setAPMMatrixData(tempData);
    }
  };

  const submitMatrixTableData = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    if (!disabled) {
      setShowModal(false);
      setApmMatrixFormData((preval) => ({
        ...preval,
        masterId: "",
        labelName: "",
      }));
    }
  };
  const handleConfirmClose = () => {
    setShowConfirm(false);
  };
  const handleModalSubmit = () => {
    setDisabled(true);
    setSpinner(true);
    let masterId = apmMatrixFormData.masterId;
    let labelName = apmMatrixFormData.labelName;
    let objectOfId = apmMatrixData.objectOfId;
    let apiSecretData = JSON.parse(localStorage.getItem("apiSecretData"));
    let formData = new FormData();
    apmMatrixData.selectedFile.map((file) => {
      return formData.append("perfMonFile", file);
    });
    formData.append("masterId", masterId);
    formData.append("apiKey", apiSecretData.key);
    formData.append("apiSecret", apiSecretData.secret);
    formData.append("labelName", labelName);
    formData.append("selectedData", JSON.stringify(objectOfId));

    submitApmSelectedMatrixData(formData)
      .then((response) => {
        if (response.status) {
          setDisabled(false);
          setShowModal(false);
          setSpinner(false);
          setLink(response.data.link);
          setShowConfirm(true);
        }
      })
      .catch((error) => {
        setShowModal(false);
        setSpinner(false);
        setDisabled(false);
        setApmMatrixFormData((preVal) => ({
          ...preVal,
          masterId: "",
          labelName: "",
        }));
      });
  };
  const style = { height: "680px" };
  const resetAllStates = () => {
    setClearApmMatrixData(false);
    setSpinner(false);
    setAPMMatrixData({
      index: null,
      data: [],
      // renderData: [],
      // title: "",
      objectOfId: {},
      submitButtonStatus: true,
      selectedFile: [],
      selectAllCheck: {},
    });
    setApmMatrixFormData({
      masterId: "",
      labelName: "",
      filesArray: [],
      submitButtonStatus: true,
    });
    setShowModal(false);
    setShowConfirm(false);
  };

  return (
    <Row className="mx-5">
      <ToastContainer></ToastContainer>
      {
        <Modal
          show={showModal}
          backdrop={disabled ? "static" : true}
          onHide={handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter Master Id and Profile name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                name="masterId"
                required
                type="number"
                placeholder="Enter Master ID"
                defaultValue={apmMatrixFormData.masterId}
                onChange={(e) => handleChange(e, "masterId")}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                name="labelName"
                required
                type="text"
                placeholder="Enter Profile Name"
                defaultValue={apmMatrixFormData.labelName}
                onChange={(e) => handleChange(e, "labelName")}
                autoComplete="off"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="fontStyle"
              disabled={disabled}
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              variant="primary"
              disabled={
                apmMatrixFormData.masterId === "" ||
                apmMatrixFormData.labelName === "" ||
                disabled
                  ? true
                  : false
              }
              className="fontStyle minWidth"
              onClick={handleModalSubmit}
            >
              {spinner ? (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              ) : (
                "Import"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      }
      {
        <Modal show={showConfirm} onHide={handleConfirmClose}>
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You can check BlazeMeter Report wih Perfmon data Here :
            <div>              
              <a href={link} target="_blank">
                {link}
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={resetAllStates}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      }
      {showApiSecretForm ? (
        <ApiAndSecretForm
          setShowApiSecretForm={setShowApiSecretForm}
        ></ApiAndSecretForm>
      ) : (
        <>
          <div className="">
            <Row className="d-flex justify-content-center align-items-center ">
              <div className="mb-3 alert alert-primary">
                <div className="py-1 text-dark fw-bolder fs-5 px-2">
                  Perfmon Connector
                </div>
                <div className="py-1 text-dark px-2">
                  Inject Server-side Metrics into a BlazeMeter Test Teport !
                </div>

                <Form
                  noValidate
                  onSubmit={(e) => handleSubmit(e)}
                  id="apiMatrixForm"
                  className="px-2 my-2"
                >
                  <Row className="">
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Form.Control
                        type="file"
                        name="filesArray"
                        required
                        multiple
                        accept=".csv"
                        onChange={(e) => handleChange(e, "files")}
                      />
                    </Form.Group>
                    <Button
                      type="submit"
                      disabled={apmMatrixFormData.submitButtonStatus}
                      className="col-2"
                    >
                      <span className="fontStyle">Upload</span>
                    </Button>
                    {clearApmMatrixData ? (
                      <Button
                        type="button"
                        className="col-2 ms-3"
                        onClick={() => resetAllStates()}
                      >
                        <span className="fontStyle">Clear</span>
                      </Button>
                    ) : null}
                  </Row>
                </Form>
                <Form.Label className="text-dark px-2 uploadFileMessage">
                  <font color="red">
                    Upload Files (*Multiple Files are Allowed)
                  </font>
                </Form.Label>
              </div>
              {apmMatrixData.data.length ? (
                <>
                  <MatrixTableComponent
                    apmMatrixData={apmMatrixData}
                    selectAll={(parentKey) => selectAll(parentKey)}
                    selectCheckboxData={(parentKey, childKey) => selectCheckboxData(parentKey, childKey)}
                    handleApmMatrixData = {(value) => handleApmMatrixData(value)}
                  ></MatrixTableComponent>
                  <div className="my-3 w-100 d-flex">
                    <Button
                      type="button"
                      onClick={submitMatrixTableData}
                      disabled={
                        apmMatrixData.data.length !==
                        Object.keys(apmMatrixData.objectOfId).length
                      }
                      className="ms-auto col-3"
                    >
                      <span className="fontStyle">Submit</span>
                    </Button>
                  </div>
                </>
              ) : loader ? (
                <div
                  style={style}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Loader
                    themeColor={"dark"}
                    size="medium"
                    type="converging-spinner"
                    className="loader"
                  />
                </div>
              ) : null}
            </Row>
          </div>
        </>
      )}
    </Row>
  );
};
